/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * 设备相关
 */
import axios from "../axios";
import {
    IEquipmentCreateParams,
    IEquipmentEditParams,
    IEquipmentGetData,
    IEquipmentImportData,
    IEquipmentImportParams,
    IEquipmentListData,
    IEquipmentListParams,
    IEquipmentRepeatAddParams,
    IEquipmentTypeCheckParams,
    IEquipmentTypeListData,
    IEquipmentTypeListParams,
    IEquipmentTypeModelListItem,
    IGetEquipmentListByAreaIdData
} from "./equipment.types";

/**
 * 根据区域ID获取设备 
 */
export function getEquipmentListByAreaId(areaId: number) {
    return axios<IGetEquipmentListByAreaIdData>({
        method: 'get',
        url: `/equipment/list/${areaId}`
    })
}

/**
 * 获取单个设备
 */
export function equipmentGet(params: { equipmentId: number }) {
    return axios<IEquipmentGetData>({
        method: 'get',
        url: '/equipment/get',
        params,
    })
}

/**
 * 设备删除 
 */
export function equipmentDelete(params: { id: number }) {
    return axios<boolean>({
        method: 'get',
        url: '/equipment/del',
        params,
    })
}

/**
 * 设备管理 - 新增设备 
 */
export function equipmentCreate(params: IEquipmentCreateParams) {
    return axios({
        method: 'post',
        url: '/equipment/save',
        data: params,
    })
}

/**
 * 设备管理 - 编辑设备 
 */
export function equipmentEdit(params: IEquipmentEditParams) {
    return axios({
        method: 'post',
        url: '/equipment/edit',
        data: params,
    })
}

/**
 * 设备配置 - 根据设备类型ID获取配件和模型数据
 */
export function equipmentTypeModelList(params: { equipmentTypeId: number }) {
    return axios<IEquipmentTypeModelListItem>({
        method: 'get',
        url: '/equipment/typeModel/get',
        params,
    })
}


/**
 * 获取设备类型list
 */
export function equipmentTypeList(params: IEquipmentTypeListParams) {
    return axios<IEquipmentTypeListData>({
        method: 'get',
        url: '/equipment/type/list',
        params,
    })
}

/**
 * 新增设备类型名称
 */
export function equipmentTypeAdd(params: { name: string, companyId: number }) {
    return axios({
        method: 'post',
        url: '/equipment/type/add',
        params,
    })
}

/**
 * 根据设备类型ID获取模型配置
 */
export function equipmentTypeGet(params: { equipmentTypeId: number }) {
    return axios({
        method: 'post',
        url: '/equipment/type/get',
        params,
    })
}

/**
 * 设备管理 - 设备分页列表
 */
export function equipmentList(params: IEquipmentListParams) {
    return axios<IEquipmentListData>({
        method: 'post',
        url: '/equipment/findPage',
        data: params,
    })
}

/**
 * 收藏设备
 */
export function equipmentCollect(params: { equipmentId: number }) {
    return axios({
        method: 'get',
        url: '/equipment/collect',
        params,
    })
}

/**
 * 设备类型判断
 */
export function equipmentTypeCheck(params: IEquipmentTypeCheckParams) {
    return axios({
        method: 'post',
        url: '/equipment/type/check',
        params,
    })
}

/**
 * 导入
 */
export function equipmentImport(params: IEquipmentImportParams) {
    const formData = new FormData()
    formData.append('file', params.file)
    return axios<IEquipmentImportData>({
        method: 'post',
        url: '/equipment/importFile',
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

/**
 * 重复设备添加
 */
export function equipmentRepeatAdd(params: IEquipmentRepeatAddParams) {
    return axios({
        method: 'post',
        url: '/equipment/equipmentRepeatAdd',
        data: params,
    })
}
