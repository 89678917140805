/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios";
import {
    IFetchWaveParams,
    IFetchWaveData,
    IFetchTrendData,
    IWaveListData,
    IWaveListParams,
    IWaveDetailParams,
    IWaveDetailData,
    IWaveHaveDefaultData
} from "./wave.types";

/**
 * 特征值
 */
export function fetchTrendData(params: IFetchWaveParams) {
    return axios<IFetchTrendData>({
        url: "/statistics/getDataListByHour",
        method: "get",
        params,
    });
}

/**
 * 波形
 */
export function fetchWaveData(params: IFetchWaveParams) {
    return axios<IFetchWaveData>({
        url: "/statistics/health/wave",
        method: "get",
        params,
    });
}

/**
 * 分页获取波形记录
 */
export function waveList(params: IWaveListParams) {
    return axios<IWaveListData>({
        method: 'post',
        url: '/wave/list/findPage',
        data: params,
    })
}

/**
 * 获取波形记录
 */
export function waveDetail(params: IWaveDetailParams) {
    return axios<IWaveDetailData>({
        method: 'post',
        url: '/wave/detail',
        params,
    })
}

/**
 * 获取图谱分析默认返回
 */
export function waveHaveDefault() {
    return axios<IWaveHaveDefaultData>({
        method: 'get',
        url: '/waveform/chart/waveHave'
    })
}
