/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios"
import {
    IGatewayCreateParams,
    IGatewayEditParams,
    IGatewayItem,
    IGatewayListData,
    IGatewayListPageData,
    IGatewayListPageParams,
    IGatewaySelectListData
} from "./gateway.types"

/**
 * 网关分页列表 
 */
export function gatewayListPage(params: IGatewayListPageParams) {
    return axios<IGatewayListPageData>({
        url: '/gateway/findPage',
        method: 'post',
        data: params,
    })
}

/**
 * 网关删除
 */
export function gatewayDelete(params: { id: number }) {
    return axios({
        url: '/gateway/del',
        method: 'get',
        params,
    })
}

/**
 * 新增网关
 */
export function gatewayCreate(params: IGatewayCreateParams) {
    return axios({
        url: '/gateway/save',
        method: 'post',
        data: params,
    })
}

/**
 * 编辑网关 
 */
export function gatewayEdit(params: IGatewayEditParams) {
    return axios({
        url: '/gateway/edit',
        method: 'post',
        data: params,
    })
}

/**
 * 获取单个网关
 */
export function gatewayInfo(params: { id: number }) {
    return axios<IGatewayItem>({
        url: '/gateway/get',
        method: 'get',
        params,
    })
}

/**
 * 获取选项 
 */
export function gatewaySelectList() {
    return axios<IGatewaySelectListData>({
        method: 'get',
        url: '/gateway/select/list'
    })
}

/**
 * 获取网关下拉选
 */
export function gatewayList(params?: { companyId: number }) {
    return axios<IGatewayListData>({
        method: 'get',
        url: '/gateway/list',
        params: params,
    })
}

/**
 * 下发配置 
 */
export function gatewayPushSetting(devId: number) {
    return axios({
        method: 'post',
        url: `/gateway/pushSetting/${devId}`
    })
}

/**
 * 重启
 */
export function gatewayRestart(devId: number) {
    return axios({
        method: 'post',
        url: `/gateway/restart/${devId}`
    })
}
