/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import axios from "../axios";
import {
  IGetFFTNewParams,
  IHistoryDataPageData,
  IHistoryDataPageParams,
  IHistoryDetailData,
  IHistoryDetailParams,
  IListDataPageData,
  IListDataPageParams
} from "./data.types";

/**
 * 实时数据
 */
export function listDataPage(params: IListDataPageParams) {
  return axios<IListDataPageData>({
    url: "/data/equip/findPage",
    method: "get",
    params,
  });
}

/**
 * 实时数据查询 - 波形
 */
export function getFFTNew(params: IGetFFTNewParams) {
  return axios({
    method: 'get',
    url: '/data/getFFTNew',
    params,
  })
}

/**
 *  历史数据-列表
 */
export function historyDataPage(params: IHistoryDataPageParams) {
  return axios<IHistoryDataPageData>({
    method: 'get',
    url: "/data/equip/findPageHistory",
    params,
  })
}

/**
 * 历史数据-详情
 */
export function historyDetail(params: IHistoryDetailParams) {
  return axios<IHistoryDetailData>({
    method: "get",
    url: "/data/mapping/detail",
    params,
  });
}

/**
 * 历史数据-导出
 */
export function hisotryExport() {
  return axios({
    responseType: 'blob',
    method: 'get',
    url: '/data/export/history',
    rawResponse: true,
  })
}
