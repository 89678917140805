/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from "lodash"
import moment from "moment"

export function storageValueWithTTL(key: string, value: string, ttl = 10) {
    localStorage.setItem(key, value)
    localStorage.setItem(key + '__expire_at', moment().add(ttl, 'seconds').format())
}

export function getStorageValueWithTTL(key: string) {
    const item = localStorage.getItem(key)
    if (_.isNil(item)) {
        return undefined
    }
    const expireTime = localStorage.getItem(key + '__expire_at')
    if (_.isNil(expireTime)) {
        return undefined
    }
    if (moment().isBefore(moment(expireTime))) {
        return item
    }
    return undefined
}
