/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import axios from "../axios";
import { IWxInformationListData, IWxInformationListParams } from './wxinformation.types';

/**
 * 解绑
 */
export function wxInformationDelete(id: number) {
    return axios({
        method: 'delete',
        url: `/wxInfomation/delete/${id}`
    })
}

export function wxInformationList(params: IWxInformationListParams) {
    return axios<IWxInformationListData>({
        method: 'get',
        url: '/wxInfomation/findPage',
        params,
    })
}
