/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 点检-保养库

import axios from "../../axios";
import {
    IUpkeepLibAddParams,
    IUpkeepLibDetailData,
    IUpkeepLibDetailParams,
    IUpkeepLibPageListData,
    IUpkeepLibPageListParams,
    IUpkeepLibUpdateParams
} from "./inspection_upkeep_lib.types";

/**
 * 添加
 */
export function upkeepLibAdd(params: IUpkeepLibAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/upkeep/lib/add',
        data: params,
    })
}

/**
 * 删除
 */
export function upkeepLibDelete(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/upkeep/lib/delete/${id}`,
    })
}

/**
 * 详情
 */
export function upkeepLibDetail(params: IUpkeepLibDetailParams) {
    return axios<IUpkeepLibDetailData>({
        method: 'get',
        url: '/inspection/upkeep/lib/getDetail',
        params,
    })
}

/**
 * 分页
 */
export function upkeepLibPageList(params: IUpkeepLibPageListParams) {
    return axios<IUpkeepLibPageListData>({
        method: 'get',
        url: '/inspection/upkeep/lib/pageList',
        params,
    })
}

/**
 * 修改
 */
export function upkeepLibUpdate(params: IUpkeepLibUpdateParams) {
    return axios({
        method: 'put',
        url: '/inspection/upkeep/lib/update',
        data: params,
    })
}