/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios"
import {
    IQuerySamplingTimeData,
    IQuerySamplingTimeParams,
    IQueryTemperatureAtlasData,
    IQueryTemperatureAtlasParams,
    IQueryTemperatureRiseAtlasData,
    IQueryTemperatureRiseAtlasParams,
    IQueryWaveformCharacteristicByManyData,
    IQueryWaveformCharacteristicByManyParams,
    IQueryWaveformCharacteristicParams,
    IQueryWaveformData,
    IQueryWaveformParams
} from "./wavefrom.types"

/**
 * 查询采样频率(过时...)
 */
// export function querySampleFrequency(mappingId: number) {
//     return axios({
//         method: 'get',
//         url: `/waveform/chart/sampleFrequency/${mappingId}`,
//     })
// }

/**
 * 根据mapping获取传感器类型
 */
export function getSensorTypeByPointMapping(mappingId: number) {
    return axios<string>({
        method: 'get',
        url: `/waveform/chart/getSensorTypeByPointMapping/${mappingId}`
    })
}

/**
 * 查询某段时间的采样时间
 */
export function querySamplingTime(params: IQuerySamplingTimeParams) {
    return axios<IQuerySamplingTimeData>({
        method: 'get',
        url: '/waveform/chart/samplingTime',
        params,
    })
}

/**
 * 查询波形
 */
export function queryWaveform(params: IQueryWaveformParams) {
    return axios<IQueryWaveformData>({
        method: 'get',
        url: '/waveform/chart/waveform',
        params,
    })
}

/**
 * 查询特征值
 */
export function queryWaveformCharacteristic(params: IQueryWaveformCharacteristicParams) {
    return axios<IQueryWaveformData>({
        method: 'get',
        url: '/waveform/chart/queryWaveformCharacteristic',
        params,
    })
}

/**
 * 多指标趋势
 */
export function queryWaveformCharacteristicByMany(params: IQueryWaveformCharacteristicByManyParams) {
    return axios<IQueryWaveformCharacteristicByManyData>({
        method: 'get',
        url: '/waveform/chart/getWaveformCharacteristicByMany',
        params,
    })
}

/**
 * 查询温度
 */
export function queryTemperatureAtlas(params: IQueryTemperatureAtlasParams) {
    return axios<IQueryTemperatureAtlasData>({
        method: 'get',
        url: '/waveform/chart/getTemperatureAtlas',
        params,
    })
}

/**
 * 查询温升
 */
export function queryTemperatureRiseAtlas(params: IQueryTemperatureRiseAtlasParams) {
    return axios<IQueryTemperatureRiseAtlasData>({
        method: 'get',
        url: '/waveform/chart/getTemperatureRiseAtlas',
        params,
    })
}
