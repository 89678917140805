/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios"
import {
    IBearCreateEditParams,
    IBearFindPageListData,
    IBearFindPageListParams,
    IBearGetFactoriesData,
    IBearManuFacturerListData,
    IBearPageListData,
    IBearPageListParams
} from "./bear.types";

/**
 * 获取轴承厂商
 */
export function bearGetFactories() {
    return axios<IBearGetFactoriesData>({
        method: 'get',
        url: '/bear/select/getTables',
    })
}

/**
 * 轴承列表
 */
export function bearPageList(params: IBearPageListParams) {
    return axios<IBearPageListData>({
        method: 'post',
        url: '/bear/findPage',
        data: params,
    })
}

/**
 * 轴承新增或编辑
 */
export function bearCreateEdit(params: IBearCreateEditParams) {
    return axios({
        method: 'post',
        url: '/bear/save',
        data: params,
    })
}

/**
 * 轴承删除 
 */
export function bearDelete(params: { id: number }) {
    return axios({
        method: 'get',
        url: '/bear/del',
        params,
    })
}

/**
 * 获取轴承厂商下拉选
 */
export function bearManuFacturerList() {
    return axios<IBearManuFacturerListData>({
        method: 'get',
        url: '/bear/select/list',
    })
}

/**
 * 根据厂商+型号搜索
 */
export function bearFindPageList(params: IBearFindPageListParams) {
    return axios<IBearFindPageListData>({
        method: 'post',
        url: '/bear/custom/bear/findPage',
        data: params,
    })
}

