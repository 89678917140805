/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios"
import {
    ICustomAddParams,
    ICustomEditCheckPasswordParams,
    ICustomEditParams,
    ICustomGetItem,
    ICustomListData,
    ICustomListParams,
    ICustomResetPasswordParams
} from "./customerManager.types"

/**
 * 客户列表
 */
export function customList(params: ICustomListParams) {
    return axios<ICustomListData>({
        method: 'post',
        url: '/custom/findPage',
        data: params,
    })
}

/**
 * 新增客户
 */
export function customAdd(params: ICustomAddParams) {
    return axios({
        method: 'post',
        url: '/custom/add',
        data: params,
    })
}

/**
 * 编辑客户
 */
export function customEdit(params: ICustomEditParams) {
    return axios({
        method: 'post',
        url: '/custom/edit',
        data: params
    })
}

/**
 * 客户删除
 */
export function customDelete(params: { id: number }) {
    return axios({
        method: 'get',
        url: '/custom/del',
        params,
    })
}

/**
 * 获取单个客户
 */
export function customGet(params: { id: number }) {
    return axios<ICustomGetItem>({
        method: 'get',
        url: '/custom/get',
        params,
    })
}

/**
 * 重置客户管理员密码
 */
export function customResetPassword(params: ICustomResetPasswordParams) {
    return axios({
        method: 'post',
        url: '/custom/resetPassword',
        data: params,
    })
}

/**
 * 修改校验密码
 */
export function customEditCheckPassword(params: ICustomEditCheckPasswordParams) {
    return axios({
        method: 'post',
        url: '/custom/editCheckPassword',
        data: params,
    })
}
