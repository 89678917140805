/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import Vue from 'vue'
import directive from 'v-click-outside/src/v-click-outside'
Vue.directive('jclickoutside', directive)

Vue.directive('drag', {
    inserted(el) {
        let pos = { top: 0, left: 0, x: 0, y: 0 }
        const mouseUpHandler = ev => {
            el.style.removeProperty('cursor')
            el.style.removeProperty('user-select')
            document.removeEventListener('mouseup', mouseUpHandler)
            document.removeEventListener('mousemove', mouseMoveHandler)
        }

        const mouseMoveHandler = ev => {
            const dx = ev.clientX - pos.x;
            const dy = ev.clientY - pos.y;

            el.scrollTop = pos.top - dy;
            el.scrollLeft = pos.left - dx;
        }

        const mouseDownHandler = ev => {
            el.style.cursor = 'grabbing'
            el.style.userSelect = 'none'
            pos = {
                // The current scroll
                left: el.scrollLeft,
                top: el.scrollTop,
                // Get the current mouse position
                x: ev.clientX,
                y: ev.clientY,
            };
            document.addEventListener('mouseup', mouseUpHandler)
            document.addEventListener('mousemove', mouseMoveHandler)
        }
        el.addEventListener('mousedown', mouseDownHandler);
        (el as any).__disposeMouseDown = () => {
            el.removeEventListener('mousedown', mouseDownHandler)
        }
    },
    unbind(el) {
        const disp = (el as any).__disposeMouseDown
        if (disp) {
            disp()
            delete (el as any).__disposeMouseDown
        }
    }
})
