/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { toBoolPromise } from "@/utils/task";
import { ElForm } from "element-ui/types/form";

export const utilMixin = {
    methods: {
        $validateElForm(ref: ElForm) {
            return toBoolPromise(ref.validate())
        }
    }
}
declare module 'vue/types/vue' {
    interface Vue {
        $validateElForm(ref: ElForm): Promise<boolean>
    }
}
