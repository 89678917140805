/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { Events } from '@/utils/event'
import { withLoading } from '@/utils/task'
import * as echarts from 'echarts'
import { Vue, Component, Prop, Ref, Inject, Watch } from 'vue-property-decorator'

@Component({
    name: 'EChart'
})
export class EChartImpl extends Vue {
    @Prop({
        type: Function,
    }) requestFn: (chart: echarts.ECharts) => Promise<void>

    @Inject({
        default: () => (() => () => { })
    })
    registerChildECharts: (chart: EChartImpl) => (() => void)

    @Ref()
    chartRef: HTMLDivElement

    @Ref()
    wrapperRef: HTMLDivElement

    chart: echarts.ECharts

    loading = false

    hasRequest = false

    request() {
        this.hasRequest = true
        const task = this.requestFn(this.chart)
        withLoading(this, task)
    }

    reset() {
        this.chart.setOption({}, true)
    }

    syncSize() {
        const rects = this.wrapperRef.getClientRects()
        if (rects.length > 0) {
            const rect = rects[0]
            this.chartRef.style.right = rect.width + 'px'
            this.chartRef.style.bottom = rect.height + 'px'
            return true
        }
    }

    resizeChart() {
        if (this.syncSize()) {
            this.chart.resize()
        }
    }

    @Watch('$i18n.locale')
    onLocalChange(value) {
        this.request()
    }

    mounted() {
        this.syncSize()
        this.chart = echarts.init(this.chartRef)
        Events.disposeOnComponent(this, this.registerChildECharts(this))
        Events.listenOnComponent(this, window, 'resize', () => {
            setTimeout(() => {
                // 这里在多个图表一起时候，单个全屏时候有问题
                this.resizeChart()
            }, 5)
        })
        Events.disposeEChartsOnComponent(this, 'chart')
    }
}
