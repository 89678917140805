<template>
    <el-input v-bind="$attrs" v-on="inputListeners" :value="value"></el-input>
</template>
<script>
export default {
    name: 'wrap-input',
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        IsNumber: {
            type: Boolean,
            default: false,
        },
        IsInteger: {
            type: Boolean,
            default: false,
        },
        OnlyNumber: {
            type: Boolean,
            default: false,
        },
        value: {
        }
    },
    computed: {
        inputListeners() {
            let vm = this
            return Object.assign({}, this.$listeners,
                {
                    input(value) {
                        if (vm.IsInteger) {
                            value = value.replace(/[^-\d]/g, '')
                        } else if (vm.OnlyNumber || vm.IsNumber) {
                            value = value.replace(/[^-\d\.]/g, '')
                        }
                        if (vm.IsNumber) {
                            // if (value !== '') {
                            //     value = Number(value)
                            // } else {
                            //     value = null
                            // }
                        }
                        vm.$emit('input', value)
                    },
                })
        }
    }
}
</script>
