/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_AlarmLevel2 } from '@/api/enums'
import { isFailedAndReport } from '@/api/helper'
import { IPopupListItem } from '@/api/moudules/alarm.types'
import { getAlarmColor } from '@/utils/alarm'
import { withDialogLoading, withLoading } from '@/utils/task'
import { IAlarmDetailEx, exAlarmDetail } from '@/view_helper/fault'
import _ from 'lodash'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

function makeQueryForm() {
    return {
        pageNum: 1,
        pageSize: 10,
    }
}

@Component({
    name: 'AlarmPopupList'
})
export class AlarmPopupListImpl extends Vue {
    @Prop({

    }) alarmTotal: number

    @Prop({

    }) alarmData: IPopupListItem[]


    data: IAlarmDetailEx[] = []
    pageTotal = 0

    onClose() {
        this.$emit('close')
    }

    queryForm = makeQueryForm()

    // 获取报警颜色
    getAlarmColor(alarmLevel: E_AlarmLevel2) {
        return getAlarmColor(alarmLevel);
    }

    onQuery() {
        const buildTask = async () => {
            const {
                pageNum,
                pageSize,
            } = this.queryForm
            const res = await this.$api.alarm.popupList({
                pageNum,
                pageSize,
            })
            if (isFailedAndReport(this, res)) {
                this.pageTotal = 0
                this.data = []
                return
            }
            this.pageTotal = res.data.total
            this.data = _.map(res.data.records as any, exAlarmDetail)
        }
        withDialogLoading(this, buildTask())
    }

    onReset() {
        this.queryForm = makeQueryForm()
        this.onQuery()
    }

    handleSizeChange(val: number) {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.onQuery()
    }

    handleCurrentChange(val: number) {
        this.queryForm.pageNum = val
        this.onQuery()
    }

    onView(row) {
        const {
            companyId,
            areaId,
            equipmentId,
            mappingId,
            id,
        } = row
        this.$api.alarm.alarmRecordRead({
            ids: [id]
        })
        this.$router.push({
            name: 'alarmReport',
            query: {
                companyId,
                areaId,
                equipmentId,
                mappingId,
            }
        })
        this.$emit('close')
    }

    get CanMarkRead() {
        return !_.isEmpty(this.data)
    }

    onRead() {
        const ids = _.map(this.data, d => d.id)
        const task = this.$api.alarm.alarmRecordRead({
            ids
        }).then(res => {
            if (isFailedAndReport(this, res)) {
                return
            }
            this.$message({
                type: 'success',
                message: this.$t('alarm.markHasReadedSuccess') as string,
            })
            this.onReset()
        })
        withDialogLoading(this, task)
    }

    created() {
        this.pageTotal = this.alarmTotal
        this.data = _.map(this.alarmData as any, exAlarmDetail)
    }
}
