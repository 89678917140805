/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios"
import {
    IWeeklyReportDetail,
    IWeeklyReportListData,
    IWeeklyReportListParams
} from "./yangshan.types"

//#region 周报相关
/**
 * 删除周报记录 
 */
export function weeklyReportDelete(uid: number) {
    return axios({
        method: 'delete',
        url: `/ys/weeklyReport/deleteWeeklyReport/${uid}`,
    })
}

/**
 * 下载文件
 */
export function weeklyReportDownlaodPdf(id: number) {
    return axios({
        responseType: 'blob',
        rawResponse: true,
        method: 'post',
        url: `/ys/weeklyReport/downloadPDF/${id}`
    })
}

/**
 * 根据uid获取周报详情
 */
export function weeklyReportDetail(uid: string) {
    return axios<IWeeklyReportDetail>({
        method: 'get',
        url: `/ys/weeklyReport/getReportWeeklyDetails/${uid}`
    })
}

/**
 * 分页查询
 */
export function weeklyReportList(params: IWeeklyReportListParams) {
    return axios<IWeeklyReportListData>({
        method: 'get',
        url: `/ys/weeklyReport/getWeeklyInfo`,
        params,
    })
}

/**
 * 上传文件
 */
export function weeklyReportUpdate(uid: string, form: FormData) {
    return axios({
        method: 'post',
        url: `/ys/weeklyReport/uplaodFile/${uid}`,
        data: form,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

//#endregion