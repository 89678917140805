/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios"
import {
    IDataDilutionData,
    IDataDilutionParams,
    IDataDilutionSwitchOpenStatusParams,
    IDataDilutionUpdateParams
} from "./dataDilution.type"


/**
 * 数据稀释详情
 */
export function dataDilutionGet(params: IDataDilutionParams) {
    return axios<IDataDilutionData>({
        method: 'get',
        url: '/data/dilution/getDetail',
        params
    })
}

/**
 * 数据稀释-开启/关闭
 */
export function dataDilutionSwitchOpenStatus(params: IDataDilutionSwitchOpenStatusParams) {
    return axios({
        method: 'put',
        url: '/data/dilution/openStatus',
        data: params
    })
}

/**
 * 数据稀释修改
 */
export function dataDilutionUpdate(params: IDataDilutionUpdateParams) {
    return axios({
        method: 'post',
        url: '/data/dilution/update',
        data: params
    })
}
