/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 报警灯

import axios from '../axios'
import { IAddLampParams, IClosePageData, IClosePageParams, IIampLeaveListData, IIampPageListData, IIampPageListParams, IIampTimeListData, ILampDeactivateParams, ILampDetailData, ILampDetailParams, ILampUpdateParams } from './alarmLamp.types'

/**
 * 添加
 */
export function addLamp(params: IAddLampParams) {
    return axios({
        method: 'post',
        url: '/alarm/lamp/addLamp',
        data: params
    })
}

/**
 * 关闭报警灯
 */
export function closeLamp(id: number) {
    return axios({
        method: 'put',
        url: `/alarm/lamp/closeLamp/${id}`,
    })
}
/**
 * 关闭列表
 */
export function closePage(params: IClosePageParams) {
    return axios<IClosePageData>({
        method: 'get',
        url: '/alarm/lamp/closePage',
        params
    })
}

/**
 * 启停报警灯
 */
export function deactivate(params: ILampDeactivateParams) {
    return axios({
        method: 'put',
        url: '/alarm/lamp/deactivate',
        data: params,
    })
}

/**
 * 删除
 */
export function deleteLamp(id: number) {
    return axios({
        method: 'delete',
        url: `/alarm/lamp/deleteLamp/${id}`,
    })
}


/**
 * 详情
 */
export function lampDetail(params: ILampDetailParams) {
    return axios<ILampDetailData>({
        method: 'get',
        url: '/alarm/lamp/lampDetail',
        params
    })
}

/**
 * 报警等级
 */
export function lampLeaveList() {
    return axios<IIampLeaveListData>({
        method: 'get',
        url: '/alarm/lamp/lampLeavelList',
    })
}
/**
 * 报警时间
 */
export function lampTimeList() {
    return axios<IIampTimeListData>({
        method: 'get',
        url: '/alarm/lamp/lampTimeList',
    })
}

/**
 * 分页
 */
export function lampPageList(params: IIampPageListParams) {
    return axios<IIampPageListData>({
        method: 'get',
        url: '/alarm/lamp/pageList',
        params
    })
}

/**
 * 修改
 */
export function lampUpdate(params: ILampUpdateParams) {
    return axios({
        method: 'put',
        url: '/alarm/lamp/updateLamp',
        data: params
    })
}