<template>
    <el-select v-bind="$attrs" v-on="Listeners">
        <template v-if="trans">
            <el-option v-for="item in options" :key="item" :label="trans(item)" :value="item">
            </el-option>
        </template>
        <template v-else>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </template>
    </el-select>
</template>
<script>
export default {
    name: 'wrap-select',
    props: {
        options: {
            type: Array,
            required: true,
        },
        trans: {
            type: Function,
            required: false,
        },
    },
    computed: {
        Listeners() {
            let vm = this
            return Object.assign({}, this.$listeners, {
                clear(value) {
                    let empty = null
                    if (vm.$attrs.multiple) {
                        empty = []
                    }
                    vm.$emit('input', empty)
                },
            })
        }
    },
    mounted() {
        this.$emit('mounted')
    }
}
</script>
