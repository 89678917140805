/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import Vue from "vue";
import VueRouter, { Route, RouteConfig, RouterOptions } from "vue-router";
import RouterProxyComponent from "../components/RouterProxyComponent.vue";
import { Store } from "vuex";
Vue.use(VueRouter);
import { routes as SpotCheckRoutes } from '../spot_check/router'

const routes: RouteConfig[] = [
    {
        path: "/",
        redirect: {
            name: 'login'
        },
    },
    {
        path: "/largeScreen",
        meta: {
            keepAlive: false,
            isBack: false,
            noMainLayoutComponent: true,
        },
        component: () =>
            import(
        /* webpackChunkName: "largeScreen" */ "../views/screen/LargeScreen/LargeScreen.vue"
            ),
        children: [
            {
                path: "",
                name: 'largeScreenNormal',
                meta: {
                    keepAlive: false,
                    isBack: false,
                    noMainLayoutComponent: true,
                },
                component: () =>
                    import(
                  /* webpackChunkName: "largeScreen" */ "../views/screen/LargeScreen/views/Normal.vue"
                    ),
            },
            {
                path: "ext",
                name: 'largeScreenExt',
                meta: {
                    keepAlive: false,
                    isBack: false,
                    noMainLayoutComponent: true,
                },
                component: () =>
                    import(
                  /* webpackChunkName: "largeScreen" */ "../views/screen/LargeScreen/views/Ext.vue"
                    ),
            },
        ]
    },
    {
        path: "/largeScreenAdmin",
        name: "largeScreenAdmin",
        meta: {
            keepAlive: false,
            isBack: false,
            noMainLayoutComponent: true,
        },
        component: () =>
            import(
        /* webpackChunkName: "largeScreenAdmin" */ "../views/screen/LargeScreenAdmin/LargeScreen.vue"
            ),
    },
    // 客户管理 - 租户管理
    {
        path: '/customerManagement_tenant',
        name: 'customerManagement_tenant',
        component: () => import(/* webpackChunkName: "tenantManagement" */ "../views/customerManagement/tenantManagement/tenantManagement.vue")
    },
    // 客户管理 - 数据稀释
    {
        path: '/dataDilution',
        name: 'dataDilution',
        component: () => import(/* webpackChunkName: "dataDilution" */ "../views/customerManagement/tenantManagement/views/dataDilution.vue")
    },
    // 客户管理 - OpenAPI管理
    {
        path: '/openAPI',
        name: 'openAPI',
        component: () => import(/* webpackChunkName: "tenantManagement" */ "../views/customerManagement/openAPIManagement/openAPIManagement.vue")
    },
    // 客户管理 - 菜单管理
    {
        path: '/customerManagement_menu',
        name: 'customerManagement_menu',
        component: () => import(/* webpackChunkName: "menuManagement" */ "../views/customerManagement/menuManagement/menuManagement.vue")
    },
    // 客户管理 - 字典管理
    {
        path: '/customerManagement_dictList',
        name: 'customerManagement_dictList',
    },
    // 客户管理 - 操作日志
    {
        path: '/customerManagement_operateLog',
        name: 'customerManagement_operateLog',
        component: () => import(/* webpackChunkName: "operlogManagement" */ "../views/customerManagement/operlogManagement/operlogManagement.vue")
    },
    // 客户管理 - 登录日志
    {
        path: '/customerManagement_loginLog',
        name: 'customerManagement_loginLog',
        component: () => import(/* webpackChunkName: "operlogLoginManagement" */ "../views/customerManagement/operlogLoginManagement/operlogLoginManagement.vue")
    },
    // 客户管理-APP版本管理
    {
        path: "/appver",
        name: "appver",
        component: () =>
            import(
        /* webpackChunkName: "appver" */ "../views/customerManagement/appver/appver.vue"
            ),
    },
    {
        path: '/firmwareUpgrad',
        name: 'firmwareUpgrad',
        component: () => import(/*webpackChunkName: "firmwareUpgrad"*/"../views/customerManagement/firmwareUpgrad/firmwareUpgrad.vue"),
    },
    {
        path: '/firmwareUpgradList',
        name: 'firmwareUpgradList',
        component: () => import(/*webpackChunkName: "firmwareUpgradList"*/"../views/customerManagement/firmwareUpgrad/firmwareUpgradList.vue"),
    },
    // 系统管理-APP版本更新
    {
        path: "/appverCreate",
        name: "appverCreate",
        component: () =>
            import(
        /* webpackChunkName: "appver" */ "../views/customerManagement/appver/AppVerCreate/AppVerCreate.vue"
            ),
    },
    // 系统管理-用户管理
    {
        path: "/userList",
        name: "userList",
        meta: {
            keepAlive: false,
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "userList" */ "../views/system/user/UserList.vue"
            ),
    },
    // 系统管理-用户管理-分配角色
    {
        path: "/authRole",
        name: "authRole",
        meta: {
            keepAlive: false,
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "authRole" */ "../views/system/user/views/authRole/authRole.vue"
            ),
    },
    // 系统管理-用户管理-分配权限
    {
        path: "/authAuthority",
        name: "authAuthority",
        meta: {
            keepAlive: false,
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "authAuthority" */ "../views/system/user/views/authAuthority/authAuthority.vue"
            ),
    },
    // 系统管理-权限管理
    {
        path: '/authorityManagement',
        name: 'authorityManagement',
        component: () => import(/* webpackChunkName: "authorityManagement" */ "../views/system/authorityManagement/authorityManagement.vue")
    },
    // 系统管理-用户管理-邮件模板
    {
        path: "/emailManagement",
        name: "emailManagement",
        component: () =>
            import(
        /* webpackChunkName: "emailManager" */ "../views/system/email/emailManager/emailManager.vue"
            ),
    },
    // 系统管理-用户管理-邮件模板 创建、编辑
    {
        path: "/emailCreateEdit",
        name: "emailCreateEdit",
        component: () =>
            import(
        /* webpackChunkName: "emailCreateEdit" */ "../views/system/email/emailCreateEdit/emailCreateEdit.vue"
            ),
    },
    // 系统管理-角色管理
    {
        path: "/roleList",
        name: "roleList",
        meta: {
            keepAlive: false,
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "roleList" */ "../views/system/role/RoleList.vue"
            ),
    },
    // 系统管理-角色管理-角色人员
    {
        path: "/roleUser",
        name: "roleUser",
        meta: {
            keepAlive: false,
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "roleUser" */ "../views/system/role/RoleUser.vue"
            ),
    },
    // 系统管理-菜单管理
    {
        path: "/menuList",
        name: "menuList",
        meta: {
            keepAlive: false,
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "menuList" */ "../views/system/menu/MenuList.vue"
            ),
    },
    // 系统管理-字典管理
    {
        path: "/dicList",
        name: "dicList",
        meta: {
            keepAlive: false,
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "dicList" */ "../views/system/dic/DicList.vue"
            ),
    },
    // 系统管理-字典管理-字典数据
    {
        path: "/dicListData",
        name: "dicListData",
        meta: {
            keepAlive: false,
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "dicListData" */ "../views/system/dic/DicListData.vue"
            ),
    },
    // 系统管理-操作日志
    {
        path: "/operateLogList",
        name: "operateLogList",
        meta: {
            keepAlive: false,
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "operateLogList" */ "../views/system/log/OperateLogList.vue"
            ),
    },
    // 系统管理-登录日志
    {
        path: "/loginLogList",
        name: "loginLogList",
        meta: {
            keepAlive: false,
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "loginLogList" */ "../views/system/log/LoginLogList.vue"
            ),
    },
    {
        path: "/faultAnalysis",
        name: "faultAnalysis",
        redirect: {
            name: "faultAnalysisDefault",
        },
        component: RouterProxyComponent,
        children: [
            {
                path: "alarmReport",
                name: "alarmReport",
                meta: {
                    keepAlive: false,
                    isBack: false,
                },
                component: () =>
                    import(
            /* webpackChunkName: "alarmReport" */ "../views/alarm/AlarmReport.vue"
                    ),
            },
            // 故障诊断-详情
            {
                path: "faultAnalysisDetail",
                name: "faultAnalysisDetail",
                meta: {
                    keepAlive: false,
                    isBack: false,
                    parentName: 'faultAnalysisDefault'
                },
                component: () =>
                    import(
            /* webpackChunkName: "faultAnalysisDetail" */ "../views/alarm/FaultAnalysisDetail.vue"
                    ),
            },
            // 故障诊断
            {
                path: "",
                name: "faultAnalysisDefault",
                meta: {
                    isBack: false,
                },
                component: () =>
                    import(
            /* webpackChunkName: "FaultDiagnosisRoot" */ "../views/alarm/FaultDiagnosisRoot/FaultDiagnosisRoot.vue"
                    ),
            },
            // 设备诊断
            {
                path: "faultAnalysisEquipment",
                name: "faultAnalysisEquipment",
                meta: {
                    isBack: false,
                    parentName: "faultAnalysisDefault",
                },
                component: () =>
                    import(
            /* webpackChunkName: "faultAnalysis" */ "../views/alarm/FaultAnalysis.vue"
                    ),
            },
        ],
    },
    // 健康监测-首页
    {
        path: "/healthTesting",
        name: "healthTesting",
        meta: {
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "healthTesting" */ "../views/health/HealthTesting.vue"
            ),
    },
    // 健康监测-监测状态
    {
        path: "/checkStatus",
        name: "checkStatus",
        meta: {
            isBack: false,
            parentName: 'healthTesting',
        },
        component: () =>
            import(
        /* webpackChunkName: "checkStatus" */ "../views/health/CheckStatus.vue"
            ),
    },
    // 健康监测-分数列表
    {
        path: "/scoreList",
        name: "scoreList",
        meta: {
            isBack: false,
            parentName: 'healthTesting',
        },
        component: () =>
            import(
        /* webpackChunkName: "scoreList" */ "../views/health/ScoreList.vue"
            ),
    },
    // 健康监测-参数分析
    {
        path: "/pointAnalysis",
        name: "pointAnalysis",
        meta: {
            parentName: 'healthTesting'
        },
        component: () =>
            import(
        /* webpackChunkName: "pointAnalysis" */ "../views/health/PointAnalysis.vue"
            ),
    },
    // 波形频谱
    {
        path: "/waveSpectrum",
        name: "waveSpectrum",
        meta: {
            keepAlive: false,
            isBack: false,
        },
        component: () =>
            import(
        /* webpackChunkName: "waveSpectrum" */ "../views/wave/WaveSpectrum.vue"
            ),
    },
    // 在线监测
    {
        path: "/onlineMonitor",
        name: "onlineMonitor",
        redirect: { name: "onlineMonitorDefault" },
        component: RouterProxyComponent,
        children: [
            {
                path: "device",
                name: "onlineDevice",
                component: () =>
                    import(
            /* webpackChunkName: "onlineDevice" */ "../views/online/OnlineDevice.vue"
                    ),
            },
            // 在线监测-测点
            {
                path: "point",
                name: "onlinePoint",
                component: () =>
                    import(
            /* webpackChunkName: "onlinePoint" */ "../views/online/OnlinePoint.vue"
                    ),
            },
            // 在线监测-波形记录
            {
                path: "waveRecord",
                name: "waveRecord",
                component: () =>
                    import(
            /* webpackChunkName: "waveRecord" */ "../views/online/WaveRecord.vue"
                    ),
            },
            // 在线监测-特征值
            {
                path: "waveEigenvalue",
                name: "waveEigenvalue",
                component: () =>
                    import(
            /* webpackChunkName: "waveEigenvalue" */ "../views/online/WaveEigenvalue.vue"
                    ),
            },
            {
                path: "",
                name: "onlineMonitorDefault",
                component: () =>
                    import(
            /* webpackChunkName: "onlineMonitor" */ "../views/online/OnlineMonitor.vue"
                    ),
            },
        ],
    },
    //数据查询
    {
        path: '/dataSearch',
        name: 'dataSearch',
        redirect: { name: 'realtimeData' },
        component: RouterProxyComponent,
        children: [
            // 数据查询-实时数据
            {
                path: "realtimeData",
                name: "realtimeData",
                meta: {
                    isBack: false,
                },
                component: () =>
                    import(
        /* webpackChunkName: "realtimeData" */ "../views/dataSearch/RealtimeData.vue"
                    ),
            },
            // 数据查询-历史数据
            {
                path: "historyData",
                name: "historyData",
                meta: {
                    isBack: false,
                },
                component: () =>
                    import(
        /* webpackChunkName: "historyData" */ "../views/dataSearch/HistoryData.vue"
                    ),
            },
            // 数据查询-历史测点详情
            {
                path: "historyPoint",
                name: "historyPoint",
                meta: {
                    isBack: false,
                    parentName: "historyData",
                },
                component: () =>
                    import(
        /* webpackChunkName: "historyPoint" */ "../views/dataSearch/HistoryPoint.vue"
                    ),
            },
            // 数据查询-周报
            {
                path: "weeklyReport",
                name: "weeklyReport",
                component: () =>
                    import(
        /* webpackChunkName: "weeklyReport" */ "../views/dataSearch/weeklyReport/weeklyReport.vue"
                    ),
            },
            // 数据查询-周报编辑
            {
                path: "weeklyReportCreateAndEdit",
                name: "weeklyReportCreateAndEdit",
                component: () =>
                    import(
        /* webpackChunkName: "weeklyReportCreateAndEdit" */ "../views/dataSearch/weeklyReport/weeklyReportCreateAndEdit/weeklyReportCreateAndEdit.vue"
                    ),
                meta: {
                    noMainLayoutComponent: true,
                }
            },
            // 数据查询-洋山历史
            {
                path: "RVT50weeklyReport",
                name: "RVT50weeklyReport",
                component: () =>
                    import(
          /* webpackChunkName: "RVT50weeklyReport" */ "../views/yangshan/weeklyReport/weeklyReport.vue"
                    ),
            },
            //数据查询-洋山历史预览
            {
                path: "RVT50weeklyReportPreview/:uid",
                name: "RVT50weeklyReportPreview",
                component: () =>
                    import(
          /* webpackChunkName: "RVT50weeklyReport" */ "../views/yangshan/weeklyReportPreview/weeklyReportPreview.vue"
                    ),
                meta: {
                    noMainLayoutComponent: true,
                }
            }
        ]
    },
    // 配置管理
    {
        path: "/configureManagement",
        name: "configureManagement",
        redirect: "configureManagementDefault",
        component: RouterProxyComponent,
        children: [
            // 设备大屏
            {
                path: "deviceScreen",
                name: "deviceScreen",
                meta: {
                    keepAlive: false,
                    isBack: false,
                },
                component: () =>
                    import(
        /* webpackChunkName: "deviceScreen" */ "../views/device/DeviceScreen.vue"
                    ),
            },
            // 设备大屏-编辑
            {
                path: "deviceScreenEdit",
                name: "deviceScreenEdit",
                meta: {
                    keepAlive: false,
                    isBack: false,
                },
                component: () =>
                    import(
        /* webpackChunkName: "deviceScreenEdit" */ "../views/device/DeviceScreenEdit.vue"
                    ),
            },
            {
                path: "subCompany",
                name: "subCompanyManagement",
                component: () =>
                    import(
          /* webpackChunkName: "subCompanyManagement" */ "../views/configureManagement/subCompanyManagement/subCompanyManagement.vue"
                    ),
            },
            {
                path: "uploadImage_Company",
                name: "uploadImage_Company",
                component: () =>
                    import(
          /* webpackChunkName: "subCompanyManagement" */ "../views/configureManagement/subCompanyManagement/subview/uploadImage_Comany.vue"
                    ),
            },
            {
                path: "uploadImage_Area",
                name: "uploadImage_Area",
                component: () =>
                    import(
          /* webpackChunkName: "subCompanyManagement" */ "../views/configureManagement/subCompanyManagement/subview/uploadImage_Area.vue"
                    ),
            },
            {
                path: "gateway",
                name: "gatewayManagement",
                component: () =>
                    import(
          /* webpackChunkName: "gatewayManagement" */ "../views/configureManagement/gatewayManagement/gatewayManagement.vue"
                    ),
            },
            {
                path: "model",
                name: "modelManagement",
                component: () =>
                    import(
          /* webpackChunkName: "modelManagement" */ "../views/configureManagement/modelManagement/modelManagement.vue"
                    ),
            },
            {
                path: "equipment",
                name: "equipmentManagement",
                component: () =>
                    import(
          /* webpackChunkName: "equipmentManagement" */ "../views/configureManagement/equipmentManagement/equipmentManagement.vue"
                    ),
            },
            {
                path: "mapping",
                name: "mappingManagement",
                component: () =>
                    import(
          /* webpackChunkName: "mappingManagement" */ "../views/configureManagement/mappingManagement/mappingManagement.vue"
                    ),
            },
            {
                path: "equipmentComponent",
                name: "equipmentComponentManagement",
                component: () =>
                    import(
          /* webpackChunkName: "equipmentComponentManagement" */ "../views/configureManagement/equipmentComponentManagement/equipmentComponentManagement.vue"
                    ),
            },
            {
                path: "sensor",
                name: "sensorManagement",
                component: () =>
                    import(
            /* webpackChunkName: "sensorManagement" */ "../views/configureManagement/sensorManagement/sensorManagement.vue"
                    ),
            },
            {
                path: "sensorNew",
                name: "sensorManagementNew",
                component: () =>
                    import(
            /* webpackChunkName: "sensorManagementNew" */ "../views/configureManagement/sensorManagementNew/sensorManagementNew.vue"
                    ),
            },
            {
                path: "sensorNewCreateEdit",
                name: "sensorNewCreateEdit",
                meta: {
                    parentName: "sensorManagementNew",
                },
                component: () =>
                    import(
            /* webpackChunkName: "sensorManagementNew" */ "../views/configureManagement/sensorManagementNew/view/sensorNewCreateEdit.vue"
                    ),
            },
            {
                path: "bearing",
                name: "bearingManagement",
                component: () =>
                    import(
            /* webpackChunkName: "bearingManagement" */ "../views/configureManagement/bearingManagement/bearingManagement.vue"
                    ),
            },
            {
                path: "threshold",
                name: "thresholdManagement",
                component: () =>
                    import(
            /* webpackChunkName: "thresholdManagement" */ "../views/configureManagement/thresholdManagement/thresholdManagement.vue"
                    ),
            },
            {
                path: "alarmLamp",
                name: "alarmLampManagement",
                component: () =>
                    import(
            /* webpackChunkName: "alarmLampManagement" */ "../views/configureManagement/alarmLampManagement/alarmLampManagement.vue"
                    ),
            },
            {
                path: "alarmLampCreateEdit",
                name: "alarmLampCreateEdit",
                component: () =>
                    import(
            /* webpackChunkName: "alarmLampManagement" */ "../views/configureManagement/alarmLampCreateEdit/alarmLampCreateEdit.vue"
                    ),
            },
            {
                path: "collectCard",
                name: "collectCardManagement",
                component: () =>
                    import(
            /* webpackChunkName: "collectCardManagement" */ "../views/configureManagement/collectCardManagement/collectCardManagement.vue"
                    ),
            },
            {
                path: "collectCardCreateEdit",
                name: "collectCardCreateEdit",
                meta: {
                },
                component: () =>
                    import(
            /* webpackChunkName: "collectCardManagement" */ "../views/configureManagement/collectCardManagement/view/collectCardCreateEdit.vue"
                    ),
            }
        ],
    },
    // 登录
    {
        path: "/login",
        name: "login",
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Login/Login.vue"),
        meta: {
            noMainLayoutComponent: true,
        }
    },
    ...SpotCheckRoutes,
    {
        path: '/largeScreen_X',
        name: 'largeScreen',
        redirect: {
            name: 'largeScreenNormal'
        },
    },
    // {
    //   path: "/pointMeasure",
    //   name: "pointMeasure",
    //   redirect: "/",
    // },
    // {
    //   path: "*",
    //   redirect: {
    //     name: 'login'
    //   }
    // }
];

/**
 * 只能超级管理员看到得
 */
const SuperAdminRouterNames = [
    'customerManagement_tenant',
    'customerManagement_menu',
    'customerManagement_dictList',
    'customerManagement_operateLog',
    'customerManagement_loginLog',
    'firmwareUpgrad',
    'firmwareUpgradList',
    'appver',
    'appverCreate',
    'appArticles',
    'appArticleAddEdit',
    'appBanner',
    'appBrief',
    'ask',
    'openAPI',
    'largeScreenAdmin',
    'dataDilution',
]

let hasInjectRouter = false

export function resetInjectRouter() {
    hasInjectRouter = false
}

function redirectSuperAdminRouter(router: VueRouter, store: Store<any>, to: Route, next) {
    if (store.getters.isSuperAdmin) {
        if (_.includes(SuperAdminRouterNames, to.name)) {
            return next()
        } else {
            return next({
                name: SuperAdminRouterNames[0]
            })
        }
    } else {
        if (to.path == '/__miss') {
            return next({
                name: store.getters.defaultMenuRouterName
            })
        }
        return next()
    }
}

export function makeRouter(store: Store<any>) {
    const router = new VueRouter({
        mode: "history",
        base: process.env.BASE_URL,
        routes,
    });
    router.beforeEach((to, from, next) => {
        if (to.name === 'login') {
            return next()
        }
        if (store.state.account) {
            redirectSuperAdminRouter(router, store, to, next)
        } else {
            store.dispatch('fetchAccountInfoWithMenu').then(() => {
                redirectSuperAdminRouter(router, store, to, next)
            })
        }
    })
    return router
}
