/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */
import qs from 'qs'
import axios from "../axios";
import {
  IAlarmDetail,
  IDealAlarmRecordParam,
  IGetFaultanAlysisStatisticsData,
  IGetFaultSuggestsData,
  IGetItemDetailData,
  IGetItemDetailParams,
  IPopupListData,
  IPopupListParms,
  IQueryListPageData,
  IQueryListPageParams,
} from "./alarm.types";

/**
 * 故障建议
 */
export function getFaultSuggests(data: any) {
  return axios<IGetFaultSuggestsData>({
    url: `/alarm/get/fault/analysis`,
    method: "get",
    params: data,
  });
}

/**
 * 设备故障原因百分比
 */
export function getFaultanAlysisStatistics(params) {
  return axios<IGetFaultanAlysisStatisticsData>({
    url: `/alarm/get/fault/analysis/statistics`,
    method: "get",
    params,
  });
}

/**
 * 报警列表 
 */
export function queryListPage(params: IQueryListPageParams) {
  function paramsSerializer(params) {
    return qs.stringify(params, {
      skipNulls: true,
      arrayFormat: 'repeat',
    })
  }
  return axios<IQueryListPageData>({
    method: 'get',
    url: '/alarmRecord/queryListPage',
    params,
    paramsSerializer: paramsSerializer as any,
  })
}

/**
 * 报警详情 
 */
export function getItemDetail(params: IGetItemDetailParams) {
  return axios<IGetItemDetailData>({
    method: 'get',
    url: `/alarmRecord/queryMappingList`,
    params,
  })
}

/**
 * 报警处理 
 */
export function dealAlarmRecord(params: IDealAlarmRecordParam) {
  return axios({
    method: 'put',
    url: '/alarmRecord/dealAlarmRecord',
    data: params,
  })
}

/**
 * 报警弹窗
 */
export function popupList(params: IPopupListParms) {
  return axios<IPopupListData>({
    method: 'get',
    url: '/alarmRecord/popupList',
    params
  })
}

/**
 * 读取报警记录
 */
export function alarmRecordRead(params) {
  return axios({
    method: 'post',
    url: '/alarmRecord/read',
    data: params,
  })
}