/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios";
import {
  IGetAreaMonthHealthData,
  IGetAreaMonthHealthParams,
  IGetAreaYearHealthData,
  IGetAreaYearHealthParam,
  IGetCompanyMonthScoreParams,
  IGetEquipmentHealthData,
  IGetEquipmentHealthParams,
  IGetEquipmentMonthHealthData,
  IGetEquipmentMonthHealthParams,
  IGetMappingAverageData,
  IGetMappingAverageParams,
  IGetMappingHealthData,
  IGetMappingHealthParams,
} from "./health.types";

// 监控监测

/**
 *  区域月健康评价
 */
export function getAreaMonthHealth(params: IGetAreaMonthHealthParams) {
  return axios<IGetAreaMonthHealthData>({
    method: 'get',
    url: '/health/score/getAreaMonthHealth',
    params,
  })
}

/**
 *  区域一年健康评价
 */
export function getAreaYearHealth(params: IGetAreaYearHealthParam) {
  return axios<IGetAreaYearHealthData>({
    method: 'get',
    url: '/health/score/getAreaYearHealth',
    params,
  })
}

/**
 *  公司当月综合评分
 */
export function getCompanyMonthScore(params: IGetCompanyMonthScoreParams) {
  return axios<number>({
    method: 'get',
    url: '/health/score/getCompanyMonthScore',
    params,
  })
}

/**
 *  设备每日健康评价
 */
export function getEquipmentHealth(params: IGetEquipmentHealthParams) {
  return axios<IGetEquipmentHealthData>({
    method: 'get',
    url: '/health/score/getEquipmentHealth',
    params,
  })
}

/**
 *  设备月健康评价
 */
export function getEquipmentMonthHealth(params: IGetEquipmentMonthHealthParams) {
  return axios<IGetEquipmentMonthHealthData>({
    method: 'get',
    url: '/health/score/getEquipmentMonthHealth',
    params,
  })
}

/**
 * 测点平均健康评价
 */
export function getMappingAverage(params: IGetMappingAverageParams) {
  return axios<IGetMappingAverageData>({
    method: 'get',
    url: '/health/score/getMappingAverage',
    params,
  })
}

/**
 *  测点每日健康评价
 */
export function getMappingHealth(params: IGetMappingHealthParams) {
  return axios<IGetMappingHealthData>({
    method: 'get',
    url: '/health/score/getMappingHealth',
    params,
  })
}
