/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { isFailedAndReport } from '@/api/helper'
import { withDialogLoading } from '@/utils/task'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import QRCode from 'qrcode2'

@Component({
    name: 'BindNotify'
})
export class BindNotifyImpl extends Vue {
    onConfirm() {
        this.$emit('close')
    }

    onCancel() {
        this.$emit('close')
    }
    onClose() {
        this.$emit('close')
    }

    @Ref()
    qrCodeHolderRef: HTMLDivElement

    mounted() {
        const buildTask = async () => {
            const res = await this.$api.system.userGetWeixinNoticeUrl()
            if (isFailedAndReport(this, res)) {
                return
            }
            const qrcode = new QRCode(this.qrCodeHolderRef, {
                text: res.data,
                width: 160,
                height: 160,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H
            })
        }
        withDialogLoading(this, buildTask())
    }
}
