/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import Vue from 'vue'
import { IDataWrapper } from "./types";
import { E_DialogStage } from '@/utils/dialog';

export function isFailed<T>(res: IDataWrapper<T>) {
    return res.code !== 200
}

export function isFailedListAndReport<T>(vm: Vue, res: IDataWrapper<T>, checkData = false) {
    let codeSuccess = res.code === 200
    let dataSuccess = true
    if (checkData && _.isNil(res.data)) {
        dataSuccess = false
    }
    if (codeSuccess && dataSuccess) {
        return false
    }
    vm.$message({
        message: vm.$t('errors.noData') as string,
        type: 'success'
    });
    return true
}

export function isFailedAndReport<T>(vm: Vue, res: IDataWrapper<T>) {
    if (res.code !== 200) {
        let msg = res.msg || vm.$t('errors.getDataError') as string
        if (res.code !== -1) {
            msg = res.msg
        }
        vm.$message({
            message: msg,
            type: 'error'
        })
        return true
    }
    return false
}

export function isFailedCreateOrEditAndReport<T>(vm: Vue, res: IDataWrapper<T>, stage: E_DialogStage) {
    if (res.code === 200) {
        let msg = vm.$t('common.createSuccess') as string
        if (stage === E_DialogStage.Edit) {
            msg = vm.$t('common.editSuccess') as string
        } else if (stage === E_DialogStage.Assign) {
            msg = '分配成功'
        }
        vm.$message({
            message: msg,
            type: 'success'
        });
        return false
    }
    let msg = res.msg || vm.$t('common.createFailed') as string
    if (stage === E_DialogStage.Edit) {
        msg = res.msg || vm.$t('common.editFailed') as string
    } else if (stage === E_DialogStage.Assign) {
        msg = res.msg || '分配失败'
    }
    vm.$message({
        message: msg,
        type: 'error'
    });
    return true
}
