/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 区域权限

import axios from "../axios";
import { IAuthorityRecorListData, IAuthorityRecordBindListParams, IAuthorityRecordBindUserParams, IAuthorityRecordDeleteParams, IAuthorityRecordDetailData, IAuthorityRecordDetailParams, IAuthorityRecordGetAreaListData, IAuthorityRecordGetAreaListParams, IAuthorityRecordListParams, IAuthorityRecordParamsAdd, IAuthorityRecordUpdateParams } from "./authority.types";

/**
 *  添加权限
 */
export function authorityRecordAdd(params: IAuthorityRecordParamsAdd) {
    return axios({
        method: 'post',
        url: '/authority/record/add',
        data: params,
    })
}

/**
 * 绑定列表
 */
export function authorityRecordBindList(params: IAuthorityRecordBindListParams) {
    return axios({
        method: 'get',
        url: '/authority/record/bindList',
        params,
    })
}

/**
 *  绑定用户权限
 */
export function authorityRecordBindUser(params: IAuthorityRecordBindUserParams) {
    return axios({
        method: 'post',
        url: '/authority/record/bindUserAuthority',
        data: params,
    })
}


/**
 *  删除
 */
export function authorityRecordDelete(params: IAuthorityRecordDeleteParams) {
    return axios({
        method: 'delete',
        url: '/authority/record/deleteById',
        data: params,
    })
}


/**
 *  获取所有区域
 */
export function authorityRecordGetAreaList() {
    return axios<IAuthorityRecordGetAreaListData>({
        method: 'get',
        url: '/authority/record/getAreaList',
    })
}


/**
 *  获取详情
 */
export function authorityRecordDetail(params: IAuthorityRecordDetailParams) {
    return axios<IAuthorityRecordDetailData>({
        method: 'get',
        url: '/authority/record/getDetail',
        params,
    })
}


/**
 *  分页查询
 */
export function authorityRecordList(params: IAuthorityRecordListParams) {
    return axios<IAuthorityRecorListData>({
        method: 'get',
        url: '/authority/record/selectList',
        params,
    })
}


/**
 *  修改权限
 */
export function authorityRecordUpdate(params: IAuthorityRecordUpdateParams) {
    return axios({
        method: 'put',
        url: '/authority/record/update',
        data: params,
    })
}
