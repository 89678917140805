/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from '../axios'
import { IAppListPackageData, IAppListPackageParams, IAppSaveParams } from './appver.types'

/**
 * 添加Apk升级程序
 */
export function appSave(params: IAppSaveParams) {
    return axios({
        method: 'put',
        url: '/appver/apkSave',
        data: params,
    })
}

/**
 * apk版本发布，发布会先将之前的版本下架
 */
export function appPublic(id: number) {
    return axios({
        method: 'put',
        url: `/appver/applyApk/${id}`
    })
}

/**
 * 删除Apk升级程序
 */
export function appApkDelete(id: number) {
    return axios({
        method: 'delete',
        url: `/appver/deleteApk/${id}`,
    })
}


/**
 * APP版本升级列表
 */
export function appListPackage(params: IAppListPackageParams) {
    return axios<IAppListPackageData>({
        method: 'get',
        url: '/appver/queryListPage',
        params,
    })
}
