/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import Vue from "vue";
import * as global from '../utils/global'

let installed = false
export function install(vue: typeof Vue) {
    if (installed) {
        return
    }
    vue.prototype.$global = global
    vue.mixin({
        computed: {
            G_IsDev() {
                return global.IS_DEV
            }
        }
    })
}

declare module 'vue/types/vue' {
    interface Vue {
        $global: typeof global
        G_IsDev: boolean
    }
}
