/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios";
import { IPageListData, IPageListParams, IUseLearnParams } from "./sensorLearn.types";

/**
 * 分页
 */
export function pageList(params: IPageListParams) {
    return axios<IPageListData>({
        method: 'get',
        url: '/sensorLearn/pageList',
        params,
    })
}

/**
 * 使用
 */
export function useLearn(params: IUseLearnParams) {
    return axios({
        method: 'post',
        url: '/sensorLearn/useLearn',
        data: params,
    })
}
