/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from '../axios'
import { IEmailAddTemplateParams, IEmailGetEmailAccountListData, IEmailGetTemplateDetailData, IEmailPageListData, IEmailPageListParams, IEmailUpdateTemplateParams } from './email.types'


/**
 *  添加模板
 */
export function emailAddTemplate(params: IEmailAddTemplateParams) {
    return axios({
        method: 'post',
        url: '/email/addTemplate',
        data: params,
    })
}

/**
 *  删除模板
 */
export function emailDeleteTemplate(id: number) {
    return axios({
        method: 'delete',
        url: `/email/deleteTemplate/${id}`,
    })
}


/**
 *  模板详情
 */
export function emailGetTemplateDetail(params: {
    id: number
}) {
    return axios<IEmailGetTemplateDetailData>({
        method: 'get',
        url: '/email/getDeatil',
        params,
    })
}


/**
 *  获取当前账号下的邮件
 */
export function emailGetEmailAccountList() {
    return axios<IEmailGetEmailAccountListData>({
        method: 'get',
        url: '/email/getEmailList',
    })
}


/**
 *  分页
 */
export function emailPageList(params: IEmailPageListParams) {
    return axios<IEmailPageListData>({
        method: 'get',
        url: '/email/pageList',
        params,
    })
}


/**
 *  更新模板
 */
export function emailUpdateTemplate(params: IEmailUpdateTemplateParams) {
    return axios({
        method: 'post',
        url: '/email/updateTemplate',
        data: params,
    })
}
