/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios";
import {
  IGetDataByTimeRangeData,
  IGetDataByTimeRangeParams,
  IGetDataListByThParams,
  IGetFFTData,
  IGetFFTParams
} from "./statistics.types";

/**
 * 频域
 */
export function getFFT(params: IGetFFTParams) {
  return axios<IGetFFTData>({
    url: "/statistics/getFFTNew",
    method: "get",
    params,
  });
}

/**
 * 健康监测-测点信息
 */
export function getHealthPoint(params: {
  mappingId: number
}) {
  return axios<any>({
    url: "/statistics/health/point",
    method: "get",
    params,
  });
}

/**
 * 在线监测测点24小时或者6小时特征值
 */
export function getDataListByTh(params: IGetDataListByThParams) {
  return axios({
    method: 'get',
    url: '/statistics/getDataListByTh',
    params
  })
}

/**
 * 根据单个时间点 往前推2小时+往后推2小时 查特征值
 */
export function getDataByTimeRange(params: IGetDataByTimeRangeParams) {
  return axios<IGetDataByTimeRangeData>({
    method: 'get',
    url: '/statistics/getDataByTimeRange',
    params,
  })
}
