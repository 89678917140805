/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { Events } from '@/utils/event'
import { toPx } from '@/utils/style'
import _ from 'lodash'
import { Component, Prop, Vue, Ref } from 'vue-property-decorator'

/**
 * 腾讯会议会导致不能拖拽，暂时禁用这个功能
 */
const ENABLE_DRAG = true


export interface IMenuItem {
    /**
     * 暂时用于在release环境中屏蔽一些不展示的menu
     */
    id?: string
    disabled?: boolean
    icon: string
    icon_active: string
    title: string
    children?: {
        title: string,
        router?: string
        permission?: string,
    }[]
    isSuperAdminMenu?: boolean
    router?: string
    isActive?: boolean
    permission?: string
}

interface IMenuItemEx extends IMenuItem {
    hasChildren: boolean
}

/**
 * 控制器状态
 */
enum E_ControlStatus {
    /**
     * 吸附在左边
     */
    AdsorptionLeft = 'AdsorptionLeft',
    /**
     * 吸附在右边
     */
    AdsorptionRight = 'AdsorptionRight',
    /**
     * 自由
     */
    Free = 'Free',
}

/**
 * 容器位置
 */
enum E_ContainerLocation {
    Left = 'Left',
    Right = 'Right',
}

@Component({
    name: "MainMenu"
})
export class MainMenuImpl extends Vue {
    @Prop({
        type: Array,
        default: () => []
    }) content: IMenuItem[]

    get Content() {
        return _.flatMap(this.content, item => {
            if (item.disabled) {
                return []
            }
            return [{
                ...item,
                hasChildren: !_.isEmpty(item.children)
            } as IMenuItemEx]
        })
    }

    containerLocation = E_ContainerLocation.Left

    get SubMenuPlace() {
        if (this.containerLocation === E_ContainerLocation.Left) {
            return 'right'
        } else {
            return 'left'
        }
    }

    get IsCn() {
        return this.$i18n.locale === 'cn'
    }

    /**
     * 是否已经折叠
     */
    collapse = true

    /**
     * 控制器状态
     */
    controlState = E_ControlStatus.AdsorptionLeft


    startPoint = {
        x: 0,
        y: 0,
    }
    lastPoint = {
        x: 0,
        y: 0,
    }

    onMoveing = false
    hasMousedown = false

    get Dragging() {
        return this.onMoveing && this.hasMousedown
    }

    delaySetMousedown = false

    onControlMousedown(ev: MouseEvent) {
        if (!ENABLE_DRAG) {
            return
        }
        this.delaySetMousedown = true
        // hack 腾讯会议不能点开问题
        setTimeout(() => {
            if (this.delaySetMousedown) {
                this.hasMousedown = true
            }
        }, 50)
        this.startPoint = {
            x: ev.offsetX,
            y: ev.offsetY
        }
    }

    onControlMouseup(ev: MouseEvent) {
        if (!ENABLE_DRAG) {
            return
        }
        this.hasMousedown = false
        this.delaySetMousedown = false
        if (this.onMoveing) {
            setTimeout(() => {
                this.onMoveing = false
            })
        }
    }

    onControlClick() {
        if (this.onMoveing) {
            return
        }
        this.collapse = false
    }

    onHiddenClick() {
        this.collapse = true
        this.$nextTick(() => {
            if (this.controlState === E_ControlStatus.Free) {
                this.controlRef.style.left = toPx(this.lastPoint.x)
                this.controlRef.style.top = toPx(this.lastPoint.y)
            }
        })
    }

    vcoConfig = {
        handler: this.onClickoutside,
        middleware: this.middleware,
    }

    middleware(event) {
        const path = (event.composedPath && event.composedPath()) || event.path || []
        for (let idx = 0; idx < path.length; idx++) {
            const p = path[idx]
            if (p && p.classList && p.classList.contains('main-menu-sub-button-wrapper')) {
                return false
            }
        }
        return true
    }

    onClickoutside() {
        this.onHiddenClick()
    }

    @Ref()
    controlRef: HTMLDivElement

    /** lifecycle */
    mounted() {
        Events.listenOnComponent(this, document, 'mouseup', (ev) => {
            this.hasMousedown = false
        })
        Events.listenOnComponent(this, document, 'mousemove', (ev: MouseEvent) => {
            if (this.hasMousedown) {
                this.onMoveing = true
                this.lastPoint.x = ev.clientX - this.startPoint.x
                this.lastPoint.y = ev.clientY - this.startPoint.y
                this.controlRef.style.left = toPx(this.lastPoint.x)
                const rect = this.controlRef.getClientRects()[0]
                const windowWidth = window.innerWidth
                // 贴到左边
                if (rect.left <= 0) {
                    this.controlRef.style.top = '0'
                    this.controlRef.style.left = '0'
                    if (this.controlState !== E_ControlStatus.AdsorptionLeft) {
                        this.controlState = E_ControlStatus.AdsorptionLeft
                        this.containerLocation = E_ContainerLocation.Left
                        this.hasMousedown = false
                    }
                }
                else if (rect.right > (windowWidth)) {
                    this.controlRef.style.top = '0'
                    this.controlRef.style.left = 'unset'
                    this.controlRef.style.right = '0'
                    if (this.controlState !== E_ControlStatus.AdsorptionRight) {
                        this.controlState = E_ControlStatus.AdsorptionRight
                        this.containerLocation = E_ContainerLocation.Right
                        this.hasMousedown = false
                    }
                }
                else {
                    const lastIsAdsorptionRight = (this.controlState === E_ControlStatus.AdsorptionRight)
                    this.controlState = E_ControlStatus.Free
                    if (lastIsAdsorptionRight) {
                        // 需要等待control变成另外一个状态
                        this.$nextTick(() => {
                            const rect = this.controlRef.getClientRects()[0]
                            // warning: 这里需要2次修正
                            const fixedLeft = windowWidth - rect.width - 2
                            // 1. 强制修正left
                            this.controlRef.style.left = toPx(fixedLeft)
                            // 2. 修正最后按钮的x值
                            this.startPoint.x = ev.clientX - fixedLeft
                        })
                    }
                    this.controlRef.style.top = toPx(this.lastPoint.y)
                }
            }
        })
    }
}
