/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { isFailedAndReport } from '@/api/helper'
import { withDialogLoading } from '@/utils/task'
import { ElForm } from 'element-ui/types/form'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

function makeForm() {
    return {
        oriPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    }
}

@Component({
    name: 'MotifyPassword'
})
export class MotifyPasswordImpl extends Vue {
    form = makeForm()

    validateConfirmNewPassword(rule, value, callback) {
        if (value !== this.form.newPassword) {
            callback(new Error(this.$t('account.missmatchPassword') as string));
        } else {
            callback();
        }
    }

    get Rules() {
        return {
            oriPassword: [
                { required: true, message: this.$t('account.pleaseInputOrigPassword'), trigger: 'blur' },
            ],
            newPassword: [
                { required: true, message: this.$t('account.pleaseInputNewPassword'), trigger: 'blur' },
            ],
            confirmNewPassword: [
                { required: true, message: this.$t('account.pleaseInputConfirmNewPassword'), trigger: 'blur' },
                { validator: this.validateConfirmNewPassword, trigger: 'blur' }
            ],
        }
    }

    @Ref()
    formRef: ElForm

    onConfirm() {
        const buildTask = async () => {
            const validated = await this.$validateElForm(this.formRef)
            if (!validated) {
                return
            }
            const {
                oriPassword,
                newPassword
            } = this.form
            const res = await this.$api.system.userMotifyPassword({
                oriPassword,
                newPassword,
            })
            if (isFailedAndReport(this, res)) {
                return
            }
            this.$message({
                type: 'success',
                message: this.$t('account.successMotifyPassword') as string,
            })
            this.$emit('close')
        }
        withDialogLoading(this, buildTask())
    }

    onCancel() {
        this.$emit('close')
    }

    onClose() {
        this.$emit('close')
    }
}
