<template>
    <div class="c-button" v-bind="$attrs" v-on="$listeners">
        <div class="icon">
            <img src="@/assets/image/button/add.svg" />
        </div>
        <div class="text">{{ Text }}</div>
    </div>
</template>
<script>
export default {
    name: "Close",
    props: {
        text: {
            type: String,            
        }
    },
    computed: {
        Text() {
            if(!this.text) {
                return this.$t('buttons.back')
            }
            return text
        }
    }
}
</script>
<style lang="scss" scoped>
.c-button {
    position: relative;
    display: inline-block;
    height: 32px;
    background: rgba(222, 134, 143, 0.36);
    box-sizing: border-box;
    padding: 0 12rem;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;

    &:active {
        background: rgba(222, 134, 143, 0.6);
        color: #409EFF;
        background: rgba(222, 134, 143, 0.6);
    }

    .icon {
        position: relative;
        display: inline-block;
        width: 16rem;
        height: 32rem;

        img {
            position: absolute;
            width: 16rem;
            height: 16rem;
            top: 50%;
            transform: translateY(-50%);
            left: 0rem;
        }
    }

    .text {
        display: inline-block;
        height: 32rem;
        vertical-align: top;
        line-height: 32rem;
        margin-left: 8rem;
        color: rgba(255, 255, 255, 1);
    }
}
</style>