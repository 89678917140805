<template>
    <div class="button" v-bind="$attrs" v-on="$listeners"><img v-if="hasIcon" src="@/assets/image/button/exp.svg"
            class="icon" /> {{ Text }}
    </div>
</template>
<script>
export default {
    name: 'button-export',
    props: {
        hasIcon: {
            type: Boolean,
            default: true,
        },
        text: {
            type: String,
        }
    },
    computed: {
        Text() {
            if(!this.text) {
                return this.$t('buttons.export')
            }
            return this.text
        }
    }
}
</script>
<style lang="scss" scoped>
.button {
    display: inline-flex;
    background: rgba(233, 157, 66, 0.36);
    color: rgba(239, 239, 239, 1);
    // width: 73px;
    height: 32px;
    padding: 0 10px;
    line-height: 32px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    border: 1px solid rgba(233, 157, 66, 0.36);
    align-items: center;

    &:active {
        background: #66b1ff;
    }

    .icon {
        margin-left: 8px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
    }
}
</style>