/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 点检-隐患

import axios from "../../axios";
import { IDangerAddHandleParams, IDangerAddParams, IDangerDetailData, IDangerDetailParams, IDangerPageListData, IDangerPageListParams, IDangerUpdateParams } from "./inspection_danger.types";

/**
 * 添加
 */
export function dangerAdd(params: IDangerAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/danger/add',
        data: params,
    })
}

/**
 * 指派
 */
export function dangerAddHandle(params: IDangerAddHandleParams) {
    return axios({
        method: 'post',
        url: '/inspection/danger/addHandle',
        data: params,
    })
}

/**
 * 删除
 */
export function dangerDelete(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/danger/delete/${id}`,
    })
}

/**
 * 详情
 */
export function dangerDetail(params: IDangerDetailParams) {
    return axios<IDangerDetailData>({
        method: 'get',
        url: '/inspection/danger/getDetail',
        params,
    })
}

/**
 * 分页
 */
export function dangerPageList(params: IDangerPageListParams) {
    return axios<IDangerPageListData>({
        method: 'get',
        url: '/inspection/danger/pageList',
        params,
    })
}

/**
 * 修改
 */
export function dangerUpdate(params: IDangerUpdateParams) {
    return axios({
        method: 'put',
        url: '/inspection/danger/update',
        data: params,
    })
}
