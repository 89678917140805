/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { Vue, Component } from 'vue-property-decorator'
import { Getter, Mutation, State } from 'vuex-class'
import { resetInjectRouter } from '@/router'

import BindNotify from '@/popup/BindNotify/BindNotify.vue'
import MotifyPassword from '@/popup/MotifyPassword/MotifyPassword.vue'

enum E_MenuCommand {
    ModifyPassword = 'ModifyPassword',
    OpenAPIDocument = 'OpenAPIDocument',
    Logout = 'Logout',
    BindNotify = 'BindNotify',
}

@Component({
    name: 'AccoutHeader',
    components: {
        MotifyPassword,
        BindNotify,
    }
})
export class AccoutHeaderImpl extends Vue {
    @Getter
    currentUserNickName: string

    @Mutation
    clearStates: () => void

    E_MenuCommand = E_MenuCommand

    showMotifyPassword = false
    onMotifyPasswordClose() {
        this.showMotifyPassword = false
    }

    showBindNotify = false
    onBindNotifyClose() {
        this.showBindNotify = false
    }

    onCommand(command: E_MenuCommand) {
        if (command === E_MenuCommand.Logout) {
            this.clearStates()
            resetInjectRouter()
            this.$api.login.logout(this)
        } else if (command === E_MenuCommand.ModifyPassword) {
            this.showMotifyPassword = true
        } else if (command === E_MenuCommand.BindNotify) {
            this.showBindNotify = true
        } else if (command === E_MenuCommand.OpenAPIDocument) {
            window.open('http://d.jjsensor.com/_doc/', '_blank')
        }
    }

    @Getter
    needAlertSoonExpire: boolean

    @Getter
    expireTime: string

    @Mutation
    markHasAlertSoonExpire: () => void

    mounted() {
        if (this.needAlertSoonExpire) {
            const h = this.$createElement;
            this.$notify({
                title: this.$t('account.expireAlert') as string,
                message: h('span', { style: 'color: red' }, [
                    h('span', this.$t('account.yourAccountWillExpired') as string),
                    h('br'),
                    h('span', this.$t('account.expiredTime') + `${this.expireTime}`),
                ]),
                duration: 0
            });
            this.markHasAlertSoonExpire()
        }
    }
}
