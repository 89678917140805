/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from '../../axios'
import { IArticleAddParams, IArticleDetailData, IArticleDetailParams, IArticlePageListData, IArticlePageListParams, IArticleUpdateParams } from './inspection_article.types'

// 点检推荐文章


/**
 * 添加
 */
export function articleAdd(params: IArticleAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/article/add',
        data: params
    })
}

/**
 * 删除
 */
export function articleDelete(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/article/delete/${id}`,
    })
}

/**
 * 
 */
export function articlePageList(params: IArticlePageListParams) {
    return axios<IArticlePageListData>({
        method: 'get',
        url: '/inspection/article/pageList',
        params
    })
}

/**
 * 
 */
export function articleDetail(params: IArticleDetailParams) {
    return axios<IArticleDetailData>({
        method: 'get',
        url: '/inspection/article/getDetail',
        params
    })
}

/**
 * 
 */
export function articleUpdate(params: IArticleUpdateParams) {
    return axios({
        method: 'put',
        url: '/inspection/article/update',
        data: params
    })
}