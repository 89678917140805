/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios";

/**
 * 下载加速度时域Excel
 */
export function excelWareDownload(params: { mappingId: string, time: string }) {
    return axios({
        responseType: 'blob',
        method: 'get',
        url: '/excel/ware/download',
        params,
    })
}

/**
 * 导出数据
 */
export function excelWareExport(params: {
    waveId?: number,
    mappingId: number
}) {
    return axios({
        responseType: 'blob',
        method: 'get',
        url: '/excel/ware/export',
        params,
        rawResponse: true,
    })
}

/**
 * 导出数据
 */
export function excelDataExport(params: {
    mappingId: number,
    beginDate: string,
    endDate: string,
}) {
    return axios({
        responseType: 'blob',
        method: 'get',
        url: '/excel/data/export',
        params,
        rawResponse: true,
    })
}

