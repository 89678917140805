/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 点检班组
import axios from '../../axios'
import { IInspectionGroupAddParams, IInspectionGroupDetailData, IInspectionGroupItemListByGroupIdData, IInspectionGroupItemListByGroupIdParams, IInspectionGroupListAllData, IInspectionGroupPageListData, IInspectionGroupPageListParams, IInspectionGroupUpdateData, IInspectionGroupUserListData, IInspectionGroupUserListParams } from './inspection_group.types'

/** 
 * 添加
*/
export function inspectionGroupAdd(params: IInspectionGroupAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/group/add',
        data: params,
    })
}

/** 
 * 删除
*/
export function inspectionGroupDel(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/group/delete/${id}`,
    })
}

/** 
 * 详情
*/
export function inspectionGroupDetail(params: {
    id: number
}) {
    return axios<IInspectionGroupDetailData>({
        method: 'get',
        url: '/inspection/group/getDetail',
        params,
    })
}
/** 
 * 获取账号
*/
export function inspectionGroupUserList(params: IInspectionGroupUserListParams) {
    return axios<IInspectionGroupUserListData>({
        method: 'get',
        url: '/inspection/group/getUserList',
        params,
    })
}
/** 
 * 分页
*/
export function inspectionGroupPageList(params: IInspectionGroupPageListParams) {
    return axios<IInspectionGroupPageListData>({
        method: 'get',
        url: '/inspection/group/pageList',
        params,
    })
}
/** 
 * 修改
*/
export function inspectionGroupUpdate(params: IInspectionGroupUpdateData) {
    return axios({
        method: 'put',
        url: '/inspection/group/update',
        data: params,
    })
}

/**
 * 班组列表(用于options)
 */
export function inspectionGroupListAll() {
    return axios<IInspectionGroupListAllData>({
        method: 'get',
        url: '/inspection/group/getGroup',
    })
}

/**
 * 班组成员列表(用于options)
 */
export function inspectionGroupItemListByGroupId(params: IInspectionGroupItemListByGroupIdParams) {
    return axios<IInspectionGroupItemListByGroupIdData>({
        method: 'get',
        url: '/inspection/group/getGroupItem',
        params,
    })
}
