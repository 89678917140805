/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 点检-隐患库

import axios from "../../axios";
import {
    IDangerLibAddParams,
    IDangerLibDetailData,
    IDangerLibDetailParams,
    IDangerLibListData,
    IDangerLibPageListData,
    IDangerLibPageListParams,
    IDangerLibUpdateParams
} from "./inspection_danger_lib.types";

/**
 * 添加
 */
export function dangerLibAdd(params: IDangerLibAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/danger/lib/add',
        data: params,
    })
}

/**
 * 隐患库列表
 */
export function dangerLibList() {
    return axios<IDangerLibListData>({
        method: 'get',
        url: '/inspection/danger/lib/dangerLibList',
    })
}

/**
 * 删除
 */
export function dangerLibDelete(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/danger/lib/delete/${id}`,
    })
}

/**
 * 详情
 */
export function dangerLibDetail(params: IDangerLibDetailParams) {
    return axios<IDangerLibDetailData>({
        method: 'get',
        url: '/inspection/danger/lib/getDetail',
        params,
    })
}

/**
 * 分页
 */
export function dangerLibPageList(params: IDangerLibPageListParams) {
    return axios<IDangerLibPageListData>({
        method: 'get',
        url: '/inspection/danger/lib/pageList',
        params,
    })
}

/**
 * 修改
 */
export function dangerLibUpdate(params: IDangerLibUpdateParams) {
    return axios({
        method: 'put',
        url: '/inspection/danger/lib/update',
        data: params,
    })
}
