/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios";
import {
    IThreadoldExportParams,
    IThreadoldImportParams,
    IThresholdCreateParams,
    IThresholdEditParams,
    IThresholdGetItem,
    IThresholdIsoEquipmentListData,
    IThresholdIsoListData,
    IThresholdListPageData,
    IThresholdListPageParams
} from "./threshold.types";

/**
 * 阈值分页列表 
 */
export function thresholdListPage(params: IThresholdListPageParams) {
    return axios<IThresholdListPageData>({
        url: '/threshold/findPage',
        method: 'post',
        data: params,
    })
}

/**
 * 阈值新增 
 */
export function thresholdCreate(params: IThresholdCreateParams) {
    return axios({
        method: 'post',
        url: '/threshold/save',
        data: params,
    })
}

/**
 * 阈值编辑 
 */
export function thresholdEdit(params: IThresholdEditParams) {
    return axios({
        method: 'post',
        url: '/threshold/save',
        data: params,
    })
}

/**
 * 获取阈值
 */
export function thresholdGet(params: { id: number }) {
    return axios<IThresholdGetItem>({
        method: 'get',
        url: '/threshold/get',
        params,
    })
}

/**
 * 阈值删除 
 */
export function thresholdDelete(params: { id: string }) {
    return axios({
        method: 'post',
        url: '/threshold/del',
        params
    })
}

/**
 * iso下拉选 
 */
export function thresholdIsoList() {
    return axios<IThresholdIsoListData>({
        method: 'get',
        url: '/threshold/iso/list',
    })
}

/**
 * 获取iso机器类型标题+设备类型下拉
 */
export function thresholdIsoEquipmentList(params: { isoName: string }) {
    return axios<IThresholdIsoEquipmentListData>({
        method: 'get',
        url: '/threshold/iso/equipment/list',
        params,
    })
}

/**
 * 获取iso机器类型下拉
 */
export function thresholdIsoSpeedList(params: {
    isoName: string,
    IdevType: string
}) {
    return axios({
        method: 'get',
        url: '/threshold/iso/speed/list',
        params,
    })
}

/**
 * 导入
 */
export function threadoldImport(params: IThreadoldImportParams) {
    const f = new FormData()
    f.append('file', params.file)
    return axios({
        method: 'post',
        url: '/threshold/importFile',
        data: f,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

/**
 * 导出
 */
export function threadoldExport(params: IThreadoldExportParams) {
    return axios({
        rawResponse: true,
        responseType: 'blob',
        method: 'get',
        url: '/threshold/exportFile',
        params,
    })
}

/**
 * 通过传感器id 查询对应阈值 
 */
export function thresholdGetSensorByMappingId(mappingId: number) {
    return axios({
        method: 'get',
        url: `/threshold/getSensorById/${mappingId}`
    })
}
