/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from '../../axios'
import { IBriefDetailData, ICompanyBrief } from './inspection_brief.types'

/**
 * 添加公司简介
 */
export function briefAdd(params: ICompanyBrief) {
    return axios({
        method: 'post',
        url: '/inspection/brief/add',
        data: params
    })
}

/**
 * 详情
 */
export function briefDetail() {
    return axios<IBriefDetailData>({
        method: 'get',
        url: '/inspection/brief/getDetail',
    })
}
