/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios"
import {
    IWeeklyReportDetail,
    IWeeklyReportDetailParams,
    IWeeklyReportExportPdfParams,
    IWeeklyReportHistoryPageData,
    IWeeklyReportHistoryPageParams,
    IWeeklyReportListItemData,
    IWeeklyReportListPageParams
} from "./weeklyReport.types"

/**
 * 监测周报 - 分页查询
 */
export function weeklyReportListPage(params: IWeeklyReportListPageParams) {
    return axios<IWeeklyReportListItemData>({
        method: 'get',
        url: '/monitor/week/getReport',
        params,
    })
}

/**
 * 历史周报 - 分页列表
 */
export function weeklyReportHistoryPage(params: IWeeklyReportHistoryPageParams) {
    return axios<IWeeklyReportHistoryPageData>({
        method: 'get',
        url: '/monitor/week/getReportHistory',
        params,
    })
}

/**
 * 周报详情
 */
export function weeklyReportDetail(params: IWeeklyReportDetailParams) {
    return axios<IWeeklyReportDetail>({
        method: 'get',
        url: '/monitor/week/getWeekReportDetail',
        params,
    })
}

/**
 * 编辑周报
 */
export function weeklyReportEdit(params: IWeeklyReportDetail) {
    return axios<number>({
        method: 'post',
        url: '/monitor/week/updateReport',
        data: params,
    })
}

/**
 * 周报删除
 */
export function weeklyReportDel(id: number) {
    return axios({
        method: 'delete',
        url: `/monitor/week/updateReport/${id}`,
    })
}

/**
 * 下载Pdf
 */
export function weeklyReportExportPdf(params: IWeeklyReportExportPdfParams) {
    return axios<string>({
        method: 'post',
        url: '/monitor/week/downloadPdf',
        data: params,
    })
}
