/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios";
import {
  IGetMappingOptionsParams,
  IMappingCreateParams,
  IMappingEditParams,
  IMappingInfo,
  IMappingListPageData,
  IMappingListPageParams,
  IMappingOptions
} from "./mapping.types";

/**
 * 获取测点下拉框
 */
export function getMappingOptions(equipmentId: number, params?: IGetMappingOptionsParams) {
  return axios<IMappingOptions>({
    method: "get",
    url: `/mapping/list/${equipmentId}`,
    params,
  });
}

/**
 * 测点分页列表
 */
export function mappingListPage(params: IMappingListPageParams) {
  return axios<IMappingListPageData>({
    method: 'post',
    url: '/mapping/findPage',
    data: params,
  })
}

/**
 * 测点解绑
 */
export function mappingUnbindSensor(params: { mappingId: number }) {
  return axios({
    method: 'get',
    url: '/mapping/remove/sensor',
    params
  })
}

/**
 *测点新增 
 */
export function mappingCreate(params: IMappingCreateParams) {
  return axios({
    method: 'post',
    url: '/mapping/save',
    data: params,
  })
}

/**
 *测点编辑
 */
export function mappingEdit(params: IMappingEditParams) {
  return axios({
    method: 'post',
    url: '/mapping/edit',
    data: params,
  })
}

/**
 *  测点删除
 */
export function mappingDelete(params: { mappingId: string }) {
  return axios({
    method: 'post',
    url: '/mapping/del',
    params,
  })
}

/**
 * 获取某测点
 */
export function mappingInfo(params: { mappingId: number }) {
  return axios<IMappingInfo>({
    method: 'get',
    url: '/mapping/get',
    params,
  })
}
