/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import Vue from "vue";
import {
  ButtonSearch,
  ButtonReset,
  ButtonConfirm,
  ButtonCancel,
  ButtonCheck,
  ButtonExport,
  ButtonViewAll,
  ButtonRetrieval,
  ButtonDiagnose,
  ButtonImport,
  ButtonNew,
  ButtonEdit,
  ButtonDelete,
  ButtonClose,
  WlButton,
  Back,
  ButtonExpand,
  ButtonSwitch,
} from "@/components/index";


import { WrapInput, WrapSelect, WrapCarousel } from './components/wrapper/index'
import DeleteWarning from "./components/DeleteWarning/DeleteWarning.vue";
import EChart from './components/EChart/EChart.vue'

Vue.component("ButtonSearch", ButtonSearch);
Vue.component("ButtonReset", ButtonReset);
Vue.component("ButtonConfirm", ButtonConfirm);
Vue.component("ButtonCancel", ButtonCancel);
Vue.component("ButtonCheck", ButtonCheck);
Vue.component("ButtonExport", ButtonExport);
Vue.component("ButtonViewAll", ButtonViewAll);
Vue.component("ButtonRetrieval", ButtonRetrieval);
Vue.component("ButtonDiagnose", ButtonDiagnose);
Vue.component("ButtonImport", ButtonImport);
Vue.component("ButtonNew", ButtonNew);
Vue.component("ButtonExpand", ButtonExpand);

Vue.component('WrapInput', WrapInput)
Vue.component('WrapSelect', WrapSelect)
Vue.component('WrapCarousel', WrapCarousel)
Vue.component("ButtonEdit", ButtonEdit);
Vue.component("ButtonDelete", ButtonDelete);
Vue.component("ButtonClose", ButtonClose);
Vue.component("ButtonSwitch", ButtonSwitch);
Vue.component("WlButton", WlButton);
Vue.component("Back", Back);
Vue.component("DeleteWarning", DeleteWarning);
Vue.component('EChart', EChart)
