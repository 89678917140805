/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from '../../axios'
import { IAskDetailData, IAskDetailParams, IAskPageListData, IAskPageListParams, IAskReplyParams } from './inspection_ask.types'
/// 留言

/**
 * 删除问题
 */
export function askDeleteAsk(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/ask/deleteAsk/${id}`
    })
}

/**
 * 删除回复
 */
export function askDeleteReply(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/ask/deleteReply/${id}`
    })
}

/**
 * 详情
 */
export function askDetail(params: IAskDetailParams) {
    return axios<IAskDetailData>({
        method: 'get',
        url: '/inspection/ask/getDetail',
        params,
    })
}

/**
 * 分页
 */
export function askPageList(params: IAskPageListParams) {
    return axios<IAskPageListData>({
        method: 'get',
        url: '/inspection/ask/pageList',
        params,
    })
}

/**
 * 回复
 */
export function askReply(params: IAskReplyParams) {
    return axios({
        method: 'post',
        url: '/inspection/ask/reply',
        data: params
    })
}
