import { render, staticRenderFns } from "./WrapSelect.vue?vue&type=template&id=5c30b64e&"
import script from "./WrapSelect.vue?vue&type=script&lang=js&"
export * from "./WrapSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports