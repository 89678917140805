/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * 区域相关
 */
import axios from "../axios";
import {
    IAreaInfoData,
    IAreaInfoParams,
    ICreateEditAreaParams,
    IFindAreaByCidData,
    IFindAreaByCidParams,
    IGetAreaListByCompanyIdData,
    IGetAreaListByCurrentUser
} from "./area.types";

/**
 * 根据当前账号获取区域
 */
export function getAreaListByCurrentUser() {
    return axios<IGetAreaListByCurrentUser>({
        method: 'get',
        url: '/area/list'
    })
}

/**
 *  根据公司ID获取区域
 */
export function getAreaListByCompanyId(companyId: number) {
    return axios<IGetAreaListByCompanyIdData>({
        url: `/area/list/${companyId}`,
        method: 'get'
    })
}

/**
 * 创建区域
 */
export function createEditArea(params: ICreateEditAreaParams) {
    return axios({
        url: '/area/save',
        method: 'post',
        params,
    })
}


/**
 *  
 */
export function findAreaByCid(params: IFindAreaByCidParams) {
    return axios<IFindAreaByCidData>({
        url: "/area/findAreaByCid",
        method: "post",
        params
    });
}


// 分页查询
export const findPage = data => {
    return axios({
        url: "/area/findPage",
        method: "post",
        data
    });
};

// 保存
export const save = data => {
    return axios({
        url: "/area/save",
        method: "post",
        data
    });
};

// 删除
export const batchDelete = data => {
    return axios({
        url: "/area/delete",
        method: "post",
        data
    });
};

// 删除区域
export const deleteArea = data => {
    return axios({
        url: "/area/deleteArea",
        method: "post",
        data
    });
};

export const findPageAreaByName = data => {
    return axios({
        url: "area/findPageAreaByName",
        method: "post",
        data
    });
};
// 下拉框查询
export const findPageAreaByCompanyId = data => {
    return axios({
        url: "area/findPageAreaByCompanyId",
        method: "post",
        data
    });
};

// 查询机构树
export const findCompanyTree = params => {
    return axios({
        url: "/area/findTree",
        method: "get",
        params
    });
};

/**
 * 获取区域信息
 */
export function areaInfo(params: IAreaInfoParams) {
    return axios<IAreaInfoData>({
        method: 'get',
        url: '/area/get',
        params,
    })
}
