<template>
    <div class="btn-back" v-bind="$attrs" v-on="$listeners">
        <img src="@/assets/image/common/back.svg" />
        <div class="btn-back__text">{{ $t('buttons.back') }}</div>
    </div>
</template>
<script>
export default {
    name: 'Back'
}
</script>
<style lang="scss" scoped>
.btn-back {
    display: flex;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
    }

    &__text {
        color: #fff;
        font-size: 14px;
        line-height: 16px;
    }
}
</style>
