/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../../axios";
import { IRepairAddParams, IRepairDemandListData, IRepairDetailData, IRepairDetailParams, IRepairListData, IRepairListParams, IRepairMeasureListData } from "./inspection_repair.types";

/**
 * 添加
 */
export function repairAdd(params: IRepairAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/repair/add',
        data: params,
    })
}

/**
 * 删除
 */
export function repairDelete(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/repair/delete/${id}`,
    })
}

/**
 * 详情
 */
export function repairDetail(params: IRepairDetailParams) {
    return axios<IRepairDetailData>({
        method: 'get',
        url: '/inspection/repair/getDetail',
        params,
    })
}


/**
 * 分页
 */
export function repairList(params: IRepairListParams) {
    return axios<IRepairListData>({
        method: 'get',
        url: '/inspection/repair/pageList',
        params,
    })
}


/**
 * 审核
 */
export function repairExamine(params) {
    return axios({
        method: 'put',
        url: '/inspection/repair/repairExamine',
        data: params,
    })
}

/**
 * 专业需求
 */
export function repairDemandList() {
    return axios<IRepairDemandListData>({
        method: 'get',
        url: '/inspection/repair/demandList',
    })
}

/**
 * 安全措施
 */
export function repairMeasureList() {
    return axios<IRepairMeasureListData>({
        method: 'get',
        url: '/inspection/repair/measureList',
    })
}
