/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios"
import { IDictTypeListParam } from "./dict.types"

/**
 * 字典类型分页列表 
 */
export function dictTypeList(params: IDictTypeListParam) {
    return axios({
        method: 'post',
        url: '/dict/type/findPage',
        params,
    })
}

/**
 * 新增或者编辑字典类型
 */
export function dictTypeCreateEdit(params) {
    return axios({
        method: 'post',
        url: '/dict/type/save',
        data: params,
    })
}

/**
 * 字典类型删除
 */
export function dictTypeDel(params: { id: number }) {
    return axios({
        method: 'get',
        url: '/dict/type/del',
        params,
    })
}
