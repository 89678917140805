/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

//模型相关

import axios from "../axios";
import {
    IModelConfigListData,
    IModelCreateEditParams,
    IModelGetData,
    IModelPageListData,
    IModelPageListParams,
    IModelTypeGetByEquipmentTypeIdData
} from "./model.types";

/**
 * 模型分页列表
 */
export function modelPageList(params: IModelPageListParams) {
    return axios<IModelPageListData>({
        url: '/model/findPage',
        method: 'post',
        data: params,
    })
}

/**
 * 获取单个模型
 */
export function modelGet(params: { id: number }) {
    return axios<IModelGetData>({
        url: '/model/get',
        method: 'get',
        params,
    })
}

/**
 * 新增或编辑模型
 */
export function modelCreateEdit(params: IModelCreateEditParams) {
    return axios({
        url: '/model/save',
        method: 'post',
        data: params,
    })
}

/**
 * 删除模型
 */
export function modelDelete(params: { id: number }) {
    return axios({
        url: '/model/del',
        method: 'get',
        params,
    })
}

/**
 * 模型配置列表
 */
export function modelConfigList() {
    return axios<IModelConfigListData>({
        url: '/model/config',
        method: 'get',
    })
}

/**
 * 根据设备类型ID获取模型配置
 */
export function modelTypeGetByEquipmentTypeId(params: {
    equipmentTypeId: number,
}) {
    return axios<IModelTypeGetByEquipmentTypeIdData>({
        method: 'get',
        url: '/model/type/get',
        params,
    })
}
