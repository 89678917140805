/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 点检-周报

import axios from "../../axios";
import {
    IWeekReportDetailData,
    IWeekReportDetailParams,
    IWeekReportDownloadPdfParams,
    IWeekReportHistoryData,
    IWeekReportHistoryParams,
    IWeekReportListData,
    IWeekReportListParams,
    IWeekReportUpdateParams
} from "./inspection_week.types";

/**
 *  删除周报
 */
export function weekReportDelete(id) {
    return axios({
        method: 'delete',
        url: `/inspection/week/report/deleteReport/${id}`,
    })
}

/**
 *  下载pdf
 */
export function weekReportDownloadPdf(params: IWeekReportDownloadPdfParams) {
    return axios({
        method: 'post',
        url: '/inspection/week/report/downloadPdf',
        data: params
    })
}

/**
 *   周报详情
 */
export function weekReportDetail(params: IWeekReportDetailParams) {
    return axios<IWeekReportDetailData>({
        method: 'get',
        url: '/inspection/week/report/getDetail',
        params
    })
}


/**
 *  周报分页
 */
export function weekReportList(params: IWeekReportListParams) {
    return axios<IWeekReportListData>({
        method: 'get',
        url: '/inspection/week/report/getReport',
        params
    })
}

/**
 *  周报历史
 */
export function weekReportHistory(params: IWeekReportHistoryParams) {
    return axios<IWeekReportHistoryData>({
        method: 'get',
        url: '/inspection/week/report/getReportHistory',
        params
    })
}

/**
 *  编辑周报
 */
export function weekReportUpdate(params: IWeekReportUpdateParams) {
    return axios({
        method: 'post',
        url: '/inspection/week/report/updateReportDeatil',
        data: params
    })
}

