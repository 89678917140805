/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
const key = 'alarm-popup'

export function markHasAlaramPopup() {
    localStorage.setItem(key, 'true')
}

export function removeHasAlaramPopup() {
    localStorage.removeItem(key)
}

export function hasAlaramPopup() {
    return !_.isNil(localStorage.getItem(key))
}
