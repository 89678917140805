<template>
    <div class="button" v-bind="$attrs" v-on="$listeners">{{ Text }}</div>
</template>
<script>
export default {
    name: "Confirm",
    props: {
        text: {
            type: String
        }
    },
    computed: {
        Text() {
            if(!this.text) {
                return this.$t('buttons.confirm')
            }
            return this.text
        }
    }
}
</script>
<style lang="scss" scoped>
.button {
    display: inline-block;
    background: #409EFF;
    color: rgba(239, 239, 239, 1);
    width: 73px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    text-align: center;

    &:active {
        background: #66b1ff;
        border-color: #66b1ff;
    }

    .icon {
        margin-left: 8px;
        margin-right: 5px;
    }
}
</style>