<template>
    <div class="wl-button" :class="getStyle()" v-bind="$attrs" v-on="$listeners">
        <div class="icon" v-if="getShow()">
            <img :src="icon" />
        </div>
        <div class="text">{{ Text }}</div>
    </div>
</template>
<script>

import add from "@/assets/image/button/add.svg";
import del from "@/assets/image/button/delete.svg";
import edit from "@/assets/image/button/edit.svg";
import search from "@/assets/image/button/search.svg";
import reset from "@/assets/image/button/reset.svg";
import imp from "@/assets/image/button/imp.svg";
import exp from "@/assets/image/button/exp.svg";
import expand from "@/assets/image/button/expand.svg";
import sort from "@/assets/image/button/sort.svg";
import back from "@/assets/image/button/back.svg";

export default {
    name: "WlButton",
    props: {
        type: {
            type: String,
            default: "add"
        },
        text: {
            type: String,
        }
    },
    computed: {
        Text() {
            if(!this.text) {
                return this.$t('buttons.newEdit')
            }
            return this.text
        }
    },
    data() {
        return {
            icon: "",
        }
    },
    created() {
        this.initIcon();
    },
    methods: {
        /**
         * 是否展示icon
         */
        getShow() {
            const type = this.type;
            if (type == "confirm" || type == "cancel") {
                return false;
            } else {
                return true;
            }
        },
        /**
         * 根据类型获取icon
         */
        initIcon() {
            const type = this.type;
            if (type == "add") {
                this.icon = add;
            } else if (type == "del") {
                this.icon = del;
            } else if (type == "edit") {
                this.icon = edit;
            } else if (type == "search") {
                this.icon = search;
            } else if (type == "reset") {
                this.icon = reset;
            } else if (type == "confirm") {

            } else if (type == "cancel") {

            } else if (type == "imp") {
                this.icon = imp;
            } else if (type == "exp") {
                this.icon = exp;
            } else if (type == "expand") {
                this.icon = expand;
            } else if (type == "sort") {
                this.icon = sort;
            } else if (type == "back") {
                this.icon = back;
            }
        },
        /**
         * 获取样式
         */
        getStyle() {
            const type = this.type;
            if (type == "add") {
                return "add";
            } else if (type == "del") {
                return "del";
            } else if (type == "edit") {
                return "edit";
            } else if (type == "search") {
                return "search";
            } else if (type == "reset") {
                return "reset";
            } else if (type == "confirm") {
                return "search";
            } else if (type == "cancel") {
                return "cancel";
            } else if (type == "imp") {
                return "imp";
            } else if (type == "exp") {
                return "exp";
            } else if (type == "expand") {
                return "expand";
            } else if (type == "sort") {
                return "sort";
            } else if (type == "back") {
                return "cancel";
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.wl-button {
    position: relative;
    display: inline-block;
    height: 32rem;
    box-sizing: border-box;
    padding: 0 16rem;
    font-size: 14rem;
    font-weight: 500;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;

    .icon {
        position: relative;
        display: inline-block;
        width: 16rem;
        height: 30rem;
        margin-right: 8rem;

        img {
            position: absolute;
            width: 16rem;
            height: 16rem;
            top: 50%;
            transform: translateY(-50%);
            left: 0rem;
        }
    }

    .text {
        display: inline-block;
        height: 30rem;
        vertical-align: top;
        line-height: 30rem;
        color: rgba(255, 255, 255, 1);
    }
}

.add {
    background: rgba(27, 75, 116, 1);
    border: solid 1px rgba(44, 121, 187, 1);

    &:active {
        background: rgba(27, 75, 116, 0.7);
        border: solid 1px rgba(44, 121, 187, 0.7);
    }
}

.del {
    background: rgba(161, 73, 73, 1);
    border: solid 1px rgba(233, 106, 106, 1);

    &:active {
        background: rgba(161, 73, 73, 0.7);
        border: solid 1px rgba(233, 106, 106, 0.7);
    }
}

.edit {
    background: rgba(27, 116, 111, 1);
    border: solid 1px rgba(29, 178, 170, 1);

    &:active {
        background: rgba(27, 116, 111, 0.7);
        border: solid 1px rgba(29, 178, 170, 0.7);
    }
}

.search {
    background: rgba(63, 162, 255, 1);
    border: solid 1px rgba(63, 162, 255, 1);

    &:active {
        background: rgba(63, 162, 255, 0.7);
        border: solid 1px rgba(63, 162, 255, 0.7);
    }
}

.reset {
    background: rgba(3, 40, 75, 1);
    border: solid 1px rgba(0, 84, 163, 1);

    &:active {
        background: rgba(3, 40, 75, 0.7);
        border: solid 1px rgba(0, 84, 163, 0.7);
    }
}

.cancel {
    background: rgba(0, 34, 60, 1);
    border: solid 1px rgba(0, 91, 161, 1);

    &:active {
        background: rgba(0, 34, 60, 0.7);
        border: solid 1px rgba(0, 91, 161, 0.7);
    }
}

.imp {
    background: rgba(81, 121, 127, 1);
    border: solid 1px rgba(132, 158, 176, 1);

    &:active {
        background: rgba(81, 121, 127, 0.7);
        border: solid 1px rgba(132, 158, 176, 0.7);
    }
}

.exp {
    background: rgba(85, 72, 48, 1);
    border: solid 1px rgba(163, 106, 0, 1);

    &:active {
        background: rgba(85, 72, 48, 0.7);
        border: solid 1px rgba(163, 106, 0, 0.7);
    }
}

.expand {
    background: rgba(37, 41, 68, 1);
    border: solid 1px rgba(59, 66, 113, 1);

    &:active {
        background: rgba(37, 41, 68, 0.7);
        border: solid 1px rgba(59, 66, 113, 0.7);
    }
}

.sort {
    background: rgba(3, 32, 66, 1);
    border: solid 1px rgba(0, 63, 136, 1);

    &:active {
        background: rgba(3, 32, 66, 0.7);
        border: solid 1px rgba(0, 63, 136, 0.7);
    }
}
</style>