/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

export interface IDataWrapper<T = any> {
    code: number
    msg: string
    data: T
}

/**
 * Yes: '1'
 * No: '0'
 * $t('enum.E_YesNo.Yes')
 * $t('enum.E_YesNo.No')
 */
export enum E_YesNo {
    Yes = '1',
    No = '0'
}

/**
 * Yes: 1
 * No: 0
 */
export enum E_YesNoNumber {
    Yes = 1,
    No = 0,
}

/**
 * Yes: 0
 * No: 1
 */
export enum E_YesNoInverse {
    Yes = 0,
    No = 1,
}

export interface IPageParams {
    pageNum: number
    pageSize: number
}

export interface IPageData<T> {
    current: number
    orders: any[]
    searchCount: boolean
    records: T[]
    size: number
    total: number
}

export interface IOptionNumberItem {
    value: number
    label: string
}

export interface IOptionStringItem {
    value: string
    label: string
}

/**
 * 坐标
 */
export interface ICoordinate {
    /**
    * 横坐标
    */
    abscissa: number
    /**
    * 纵坐标
    */
    ordinate: number
}
