/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

//第三方密钥配置

import axios from "../axios"
import {
    IOpenApiCreateParams,
    IOpenApiDetailData,
    IOpenApiDetailParams,
    IOpenApiResetSecretKeyParams,
    IOpenApiUpdateParams
} from "./open.types"

/**
 * 
 */
export function openApiCreate(params: IOpenApiCreateParams) {
    return axios({
        method: 'post',
        url: '/open/api/add',
        data: params
    })
}

/**
 * 
 */
export function openApiDetailByUserId(params: IOpenApiDetailParams) {
    return axios<IOpenApiDetailData>({
        method: 'get',
        url: '/open/api/getDetailByUserId',
        params
    })
}

/**
 * 重置密钥
 */
export function openApiResetSecretKey(params: IOpenApiResetSecretKeyParams) {
    return axios({
        method: 'post',
        url: '/open/api/resetSecretKey',
        params
    })
}

/**
 * 
 */
export function openApiUpdate(params: IOpenApiUpdateParams) {
    return axios({
        method: 'post',
        url: '/open/api/update',
        data: params
    })
}