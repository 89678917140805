/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 巡检任务
import axios from '../../axios'
import {
    IInspectionEquipmentPageListData,
    IInspectionEquipmentPageListParams,
    IInspectionMappingEigenDataListData,
    IInspectionMappingEigenDataListParams,
    IInspectionMappingExportParams,
    IInspectionMappingExportWaveParams,
    IInspectionMappingListData,
    IInspectionMappingListParams,
    IInspectionMappingTaskMappingDetailData,
    IInspectionMappingTaskMappingDetailParams,
    IInspectionMappingWaveData,
    IInspectionMappingWaveParams,
    IInspectionTaskAllLineData,
    IInspectionTaskDeleteParams,
    IInspectionTaskDetailData,
    IInspectionTaskItem,
    IInspectionTaskPageListData,
    IInspectionTaskPageListParams,
    IInspectionTaskUpdateParams,
    InspectionMappingWaveDetailsData,
    InspectionMappingWaveDetailsParams
} from './inspection_task.types'

/**
 * 添加
 */
export function inspectionTaskAdd(params: IInspectionTaskItem) {
    return axios({
        method: 'post',
        url: '/inspection/task/add',
        data: params,
    })
}

/**
 * 所有路线
 */
export function inspectionTaskAllLine() {
    return axios<IInspectionTaskAllLineData>({
        method: 'get',
        url: '/inspection/task/allLine',
    })
}

/**
 * 删除
 */
export function inspectionTaskDelete(params: IInspectionTaskDeleteParams) {
    return axios({
        method: 'delete',
        url: `/inspection/task/delete`,
        data: params,
    })
}

/**
 * 详情
 */
export function inspectionTaskDetail(params: {
    id: number
}) {
    return axios<IInspectionTaskDetailData>({
        method: 'get',
        url: '/inspection/task/getDetail',
        params,
    })
}

/**
 * 分页
 */
export function inspectionTaskPageList(params: IInspectionTaskPageListParams) {
    return axios<IInspectionTaskPageListData>({
        method: 'get',
        url: '/inspection/task/pageList',
        params,
    })
}

/**
 * 修改
 */
export function inspectionTaskUpdate(params: IInspectionTaskUpdateParams) {
    return axios({
        method: 'put',
        url: '/inspection/task/update',
        data: params,
    })
}


//#region 测点
/**
 * 导出
 */
export function inspectionMappingExport(params: IInspectionMappingExportParams) {
    return axios({
        method: 'get',
        url: '/inspection/mapping/export',
        params,
        rawResponse: true,
        responseType: 'blob',
    })
}

/**
 * 获取特征值
 */
export function inspectionMappingEigenDataList(params: IInspectionMappingEigenDataListParams) {
    return axios<IInspectionMappingEigenDataListData>({
        method: 'get',
        url: '/inspection/mapping/getEigenDataList',
        params,
    })
}

/**
 * 获取波形
 */
export function inspectionMappingWave(params: IInspectionMappingWaveParams) {
    return axios<IInspectionMappingWaveData>({
        method: 'get',
        url: '/inspection/mapping/getWave',
        params,
    })
}

/**
 * 列表
 */
export function inspectionMappingList(params: IInspectionMappingListParams) {
    return axios<IInspectionMappingListData>({
        method: 'get',
        url: '/inspection/mapping/pageList',
        params
    })
}

/**
 * 获取波形信息
 */
export function inspectionMappingWaveDetails(params: InspectionMappingWaveDetailsParams) {
    return axios<InspectionMappingWaveDetailsData>({
        method: 'get',
        url: '/inspection/mapping/getWaveDetail',
        params,
    })
}

/**
 * 测点详情
 */
export function inspectionMappingTaskMappingDetail(params: IInspectionMappingTaskMappingDetailParams) {
    return axios<IInspectionMappingTaskMappingDetailData>({
        method: 'get',
        url: '/inspection/mapping/taskMappingDetail',
        params,
    })
}

/**
 * 波形导出
 */
export function inspectionMappingExportWave(params: IInspectionMappingExportWaveParams) {
    return axios({
        responseType: 'blob',
        method: 'get',
        url: '/inspection/mapping/exportWave',
        params,
        rawResponse: true,
    })
}


//#endregion

//#region 设备

/**
 * 分页
 */
export function inspectionEquipmentPageList(params: IInspectionEquipmentPageListParams) {
    return axios<IInspectionEquipmentPageListData>({
        method: 'get',
        url: '/inspection/equipment/pageList',
        params
    })
}

//#endregion