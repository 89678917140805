/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios"
import {
    IOnlineDetectionEquipmentData,
    IOnlineDetectionEquipmentParams,
    IOnlineDetectionHomeData,
    IOnlineDetectionHomeParams,
    IOnlineDetectionMappingData
} from "./onlineDetection.types"

/**
 * 在线检测首页
 */
export function onlineDetectionHome(params: IOnlineDetectionHomeParams) {
    return axios<IOnlineDetectionHomeData>({
        method: 'get',
        url: '/onlineDetection/home',
        params
    })
}

/**
 * 在线监测-设备
 */
export function onlineDetectionEquipment(params: IOnlineDetectionEquipmentParams) {
    return axios<IOnlineDetectionEquipmentData>({
        method: 'get',
        url: '/onlineDetection/equipment',
        params,
    })
}

/**
 * 在线监测-测点
 */
export function onlineDetectionMapping(params: {
    mappingId: number
}) {
    return axios<IOnlineDetectionMappingData>({
        method: 'get',
        url: '/onlineDetection/mapping',
        params,
    })
}
