<template>
    <el-carousel v-bind="$attrs" v-on="Listeners" ref="carouselRef" :autoplay="autoplay">
        <slot></slot>
    </el-carousel>
</template>
<script>
import _ from 'lodash'

export default {
    name: 'wrap-carousel',
    props: ['autoplay'],
    computed: {
        Listeners() {
            let vm = this
            return Object.assign({}, this.$listeners,
                {
                    change(val, oldVal) {
                        // console.log('change', val, oldVal)
                        vm.$emit('change', val, oldVal)
                        if (vm.$refs['carouselRef']) {
                            // 构造触发到达尾部事件
                            if (val === (_.size(vm.$refs['carouselRef'].items) - 1)) {
                                vm.delayFireBoundary()
                            }
                        }
                    },
                })
        }
    },
    watch: {
        autoplay: {
            // immediate: true,
            handler(value) {
                this.onAutoplayChange(value)
            }
        }
    },
    methods: {
        delayFireBoundary() {
            if (this.delayFireHandler) {
                return
            }
            this.delayFireHandler = setTimeout(() => {
                this.$emit('boundary')
            }, 3000);
        },
        onAutoplayChange(value) {
            if (value && this.$refs['carouselRef']) {
                const itemSize = _.size(this.$refs['carouselRef'].items)
                if (itemSize === 0 || itemSize === 1) {
                    this.delayFireBoundary()
                }
            }
        }
    },
    mounted() {
        this.onAutoplayChange(this.autoplay)
    }
}
</script>
