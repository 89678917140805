/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/* 设置字体rem */

let useRem = false

function onWindowResize() {
  let html = document.querySelector('html')
  let fontSize = 1
  if (useRem) {
    var width = document.body.offsetWidth; // 获取当前页面的宽度
    fontSize = width / 1920; // 设置字体大小    
  }
  console.log(fontSize, "fontSize");
  html.style.fontSize = fontSize + "px";
}

export function setRem() {
  useRem = true
  onWindowResize()
}

export function unsetRem() {
  useRem = false
  onWindowResize()
}

unsetRem()

window.addEventListener("resize", () => {
  onWindowResize();
})
