<template>
    <div class="button" v-bind="$attrs" v-on="$listeners">
        <img v-if="hasIcon" src="@/assets/image/wave/retrieval.svg" class="icon" />
        <span>{{ Text }}</span>
    </div>
</template>
<script>
export default {
    name: 'button-diagnose',
    props: {
        hasIcon: {
            type: Boolean,
            default: true,
        },
        text: {
            type: String,
        }
    },
    computed: {
        Text() {
            if (!this.text) {
                return this.$t('buttons.enableDiagnose')
            }
            return this.text
        }
    }
}
</script>
<style lang="scss" scoped>
.button {
    background: rgba(255, 160, 49, 1);
    color: rgba(239, 239, 239, 1);
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 10px;
    display: inline-flex;
    line-height: 32px;
    justify-content: center;
    align-items: center;

    &:active {
        background: rgba(255, 160, 49, 0.7);
    }

    .icon {
        margin-left: 8px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
    }
}
</style>