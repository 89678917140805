import { getCurrentInstance } from "vue";

export function useI18n() {
    const app = getCurrentInstance()
    return {
        $t: app.proxy.$t.bind(app.proxy),
        getEnumTrans() {
            return app.proxy.$enumTrans
        }
    }
}
