/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios"
import {
  ICreateSubCompanyParams as ICreateEditSubCompanyParams,
  IDelCompanyOrAreaParams,
  IFindCompanyPageData,
  IFindCompanyPageParams,
  IFindCompanyTreeData,
  IFindCompanyTreeParams,
  IGetCompanyListData,
  ISubCompanyItem
} from "./company.types"

/**
 * 获取所有公司
 */
export function getCompanyList() {
  return axios<IGetCompanyListData>({
    method: 'get',
    url: '/company/list'
  })
}

/**
 * 公司列表  
 */
export function findCompanyPage(params: IFindCompanyPageParams) {
  return axios<IFindCompanyPageData>({
    method: 'get',
    url: '/company/findPage',
    params
  })
}

/**
 * 创建子公司
 */
export function createEditSubCompany(params: ICreateEditSubCompanyParams) {
  return axios({
    method: 'post',
    url: '/company/save',
    params,
  })
}

/**
 * 获取单个子公司信息 
 */
export function getSubCompanyDetail(params: { companyId: number }) {
  return axios<ISubCompanyItem>({
    method: 'get',
    url: '/company/get',
    params
  })
}

/**
 * 删除公司或区域
 */
export function delCompanyOrArea(params: IDelCompanyOrAreaParams) {
  return axios({
    method: 'get',
    url: '/company/del',
    params,
  })
}


// 查询机构树
export function findCompanyTree(params: IFindCompanyTreeParams) {
  return axios<IFindCompanyTreeData>({
    url: "/company/findTree",
    method: "get",
    params,
  })
}


/*
 * 公司管理模块
 */

// 保存
export const save = (data) => {
  return axios({
    url: "/company/save",
    method: "post",
    data,
  })
}

// 删除
export const batchDelete = (data) => {
  return axios({
    url: "/company/delete",
    method: "post",
    data,
  });
};

//搜索子部门
export const findPageCompanyName = (data) => {
  return axios({
    url: "/company/findPageCompanyName",
    method: "post",
    data,
  });
};

// 查询公司code
export const getCompanyCode = (params) => {
  return axios({
    url: "/company/getCompanyCode",
    method: "get",
    params,
  });
};
