/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/*
 * 接口统一集成模块
 */

import * as company from "./moudules/company";
import * as area from "./moudules/area";
import * as alarm from "./moudules/alarm";
import * as login from "./moudules/login";
import * as statistics from "./moudules/statistics";
import * as equipment from "./moudules/equipment";
import * as mapping from "./moudules/mapping";
import * as fault from "./moudules/fault";
import * as wave from "./moudules/wave";
import * as health from "./moudules/health";
import * as device from "./moudules/device";
import * as threshold from "./moudules/threshold";
import * as sensor from "./moudules/sensor";
import * as gateway from './moudules/gateway'
import * as equipmentComponent from './moudules/equipmentComponent'
import * as model from './moudules/model'
import * as bear from './moudules/bear'
import * as system from "./moudules/system"
import * as excel from './moudules/excel'
import * as dataSearch from './moudules/data'
import * as file from './moudules/file'
import * as overview from './moudules/overview'
import * as onlineDetection from './moudules/onlineDetection'
import * as screen from './moudules/screen'
import * as customerManager from './moudules/customerManager'
import * as waveform from './moudules/wavefrom'
import * as weeklyReport from './moudules/weeklyReport'
import * as dict from './moudules/dict'
import * as monitor from './moudules/monitor'
import * as wxinformation from './moudules/wxinformation'
import * as yangshan from './moudules/yangshan'
import * as eigenvalue from './moudules/eigenvalue'
import * as authority from './moudules/authority'
import * as email from './moudules/email'
import * as appver from './moudules/appver'
import * as alarmLamp from './moudules/alarmLamp'
import * as sensorLearn from './moudules/sensorLearn'
import * as openApi from './moudules/open'
import * as largeScreen from './moudules/largeScreen'
import * as dataDilution from './moudules/dataDilution'

import {
  inspection_task,
  inspection_standard,
  inspection_group,
  inspection_point,
  inspection_line,
  inspection_danger,
  inspection_danger_lib,
  inspection_upkeep,
  inspection_upkeep_lib,
  inspection_repair,
  inspection_repair_lib,
  inspection_article,
  inspection_banner,
  inspection_brief,
  inspection_ask,
  inspection_week,
} from "@/spot_check/api/api";

// 默认全部导出
export default {
  company,
  area,
  alarm,
  login,
  statistics,
  equipment,
  mapping,
  fault,
  wave,
  health,
  device,
  threshold,
  sensor,
  gateway,
  equipmentComponent,
  model,
  bear,
  system,
  excel,
  dataSearch,
  file,
  overview,
  onlineDetection,
  screen,
  customerManager,
  waveform,
  weeklyReport,
  dict,
  monitor,
  wxinformation,
  yangshan,
  eigenvalue,
  authority,
  email,  
  alarmLamp,    
  appver,
  sensorLearn,
  openApi,
  largeScreen,
  dataDilution,
  inspection_standard,
  inspection_group,
  inspection_point,
  inspection_line,
  inspection_task,
  inspection_danger,
  inspection_danger_lib,
  inspection_upkeep,
  inspection_upkeep_lib,
  inspection_repair,
  inspection_repair_lib,
  inspection_article,
  inspection_banner,
  inspection_brief,
  inspection_ask,
  inspection_week,
};
