/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 设备部件
import axios from "../axios"
import {
    IEquiComponentGetGearByExtendIdParams,
    IEquipmentComponentCommonParams,
    IEquipmentComponentCreateParams,
    IEquipmentComponentDetailData,
    IEquipmentComponentEditParams,
    IEquipmentComponentEquipmentExtendData,
    IEquipmentComponentGetParams,
    IEquipmentComponentMappingData,
    IEquipmentComponentMappingParams,
    IEquipmentComponentPageListData,
    IGearLists
} from "./equipmentComponent.types"

/**
 * 部件分页列表
 */
export function equipmentComponentPageList(params: IEquipmentComponentGetParams) {
    return axios<IEquipmentComponentPageListData>({
        method: 'post',
        url: '/component/findPage',
        data: params,
    })
}


/**
 * 删除部件
 */
export function equipmentComponentDelete(params: IEquipmentComponentCommonParams) {
    return axios({
        method: 'get',
        url: '/component/del',
        params,
    })
}


/**
 * 新增部件
 */
export function equipmentComponentCreate(params: IEquipmentComponentCreateParams) {
    return axios({
        method: 'post',
        url: '/component/save',
        data: params,
    })
}

/**
 * 编辑部件
 */
export function equipmentComponentEdit(params: IEquipmentComponentEditParams) {
    return axios({
        method: 'post',
        url: '/component/edit',
        data: params,
    })
}

/**
 * 获取单个部件已经选择的测点数据
 */
export function equipmentComponentMapping(params: IEquipmentComponentMappingParams) {
    return axios<IEquipmentComponentMappingData>({
        method: 'get',
        url: '/component/getPointMappingByEquipmentId',
        params,
    })
}

/**
 *  部件详情
 */
export function equipmentComponentDetail(params: IEquipmentComponentCommonParams) {
    return axios<IEquipmentComponentDetailData>({
        method: 'get',
        url: '/component/detail',
        params,
    })
}

/**
 * 查询设备绑定的部件
 */
export function equipmentComponentEquipmentExtend(equipmentId: number, params?: {
    componentId: number
}) {
    return axios<IEquipmentComponentEquipmentExtendData>({
        method: 'get',
        url: `/component/getEquipmentExtend/${equipmentId}`,
        params,
    })
}

export function equiComponentGetGearByExtendId(params: IEquiComponentGetGearByExtendIdParams) {
    return axios<IGearLists>({
        method: 'get',
        url: `/component/getGearByExtendId`,
        params,
    })
}
