/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 巡检点

import axios from '../../axios'
import {
    IInspectionPointAddParams,
    IInspectionPointAreaImgDetailData,
    IInspectionPointDetailData,
    IInspectionPointPageListData,
    IInspectionPointPageListParams,
    IInspectionPointUpdateParams,
    IInspectionPointUpdateStatusParams,
    IInspectionPointUploadAreaImgParams
} from './inspection_point.types'

/**
 * 添加
 */
export function inspectionPointAdd(params: IInspectionPointAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/point/add',
        data: params,
    })
}

/**
 * 删除
 */
export function inspectionPointDelete(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/point/delete/${id}`,
    })
}

/**
 * 详情
 */
export function inspectionPointDetail(params: {
    id: number,
}) {
    return axios<IInspectionPointDetailData>({
        method: 'get',
        url: '/inspection/point/getDetail',
        params,
    })
}

/**
 * 分页
 */
export function inspectionPointPageList(params: IInspectionPointPageListParams) {
    return axios<IInspectionPointPageListData>({
        method: 'get',
        url: '/inspection/point/pageList',
        params,
    })
}


/**
 * 修改
 */
export function inspectionPointUpdate(params: IInspectionPointUpdateParams) {
    return axios({
        method: 'put',
        url: '/inspection/point/update',
        data: params,
    })
}

/**
 * 修改状态
 */
export function inspectionPointUpdateStatus(params: IInspectionPointUpdateStatusParams) {
    return axios({
        method: 'put',
        url: '/inspection/point/updateStatus',
        data: params,
    })
}

/**
 * 上传区域图片
 */
export function inspectionPointUploadAreaImg(params: IInspectionPointUploadAreaImgParams) {
    return axios({
        method: 'post',
        url: '/inspection/point/uploadAreaImg',
        data: params,
    })
}

/**
 * 区域图片详情
 */
export function inspectionPointAreaImgDetail(params: { id: number }) {
    return axios<IInspectionPointAreaImgDetailData>({
        method: 'get',
        url: '/inspection/point/areaImgDetail',
        params,
    })
}