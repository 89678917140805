/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../../axios";
import { IRepaireLibAddParams, IRepairLibDetailData, IRepairLibDetailParams, IRepaireLibListData, IRepaireLibListParams, IRepaireLibUpdateParams } from "./inspection_repair_lib.types";

/**
 * 添加
 */
export function repaireLibAdd(params: IRepaireLibAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/repair/lib/add',
        data: params,
    })
}


/**
 * 删除
 */
export function repaireLibDelete(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/repair/lib/delete/${id}`,
    })
}


/**
 * 详情
 */
export function repaireLibDetail(params: IRepairLibDetailParams) {
    return axios<IRepairLibDetailData>({
        method: 'get',
        url: '/inspection/repair/lib/getDetail',
        params,
    })
}


/**
 * 分页
 */
export function repaireLibList(params: IRepaireLibListParams) {
    return axios<IRepaireLibListData>({
        method: 'get',
        url: '/inspection/repair/lib/pageList',
        params,
    })
}


/**
 * 修改
 */
export function repaireLibUpdate(params: IRepaireLibUpdateParams) {
    return axios({
        method: 'put',
        url: '/inspection/repair/lib/update',
        data: params,
    })
}
