/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 大屏
import axios from "../axios"
import {
    ICompanyAreaParams,
    IEquipmentAlarmStatisticsData,
    IEquipmentMapData,
    IScreenMachineStatisticsData,
    IScreenMappingAlarmStatisticsRecordData,
    IScreenPointAlarmHistoryData
} from "./screen.types"

/**
 * 首页大屏 左侧设备统计
 */
export function screenMachineStatistics(params?: ICompanyAreaParams) {
    return axios<IScreenMachineStatisticsData>({
        method: 'get',
        url: '/screen/getMachineStatistics',
        params,
    })
}

/**
 *  查询测点报警记录
 */
export function screenPointAlarmHistory(params?: ICompanyAreaParams) {
    return axios<IScreenPointAlarmHistoryData>({
        method: 'get',
        url: '/screen/get/point/alarm/history',
        params,
    })
}

/**
 * 大屏月设备统计
 */
export function screenEquipmentAlarmStatistics(params?: ICompanyAreaParams) {
    return axios<IEquipmentAlarmStatisticsData>({
        method: 'get',
        url: '/screen/getEquipmentAlarmStatistics',
        params,
    })
}

/**
 * 大屏地图数据
 */
export function screenEquipmentMap() {
    return axios<IEquipmentMapData>({
        method: 'get',
        url: '/screen/getEquipmentMap',
    })
}

/**
 * 月测点报警记录统计 
 */
export function screenMappingAlarmStatisticsRecord(params?: ICompanyAreaParams) {
    return axios<IScreenMappingAlarmStatisticsRecordData>({
        method: 'get',
        url: '/screen/getMappingAlarmStatisticsRecord',
        params,
    })
}
