/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * 新版大屏
 */
import axios from "../axios";
import {
    ILargeScreenAddAreaFileParams,
    ILargeScreenAddCompanyFileParams,
    ILargeScreenAlaramHistoryParams,
    ILargeScreenGetAreaFileData as ILargeScreenGetAreaFileData,
    ILargeScreenGetAreaFileParams as ILargeScreenGetAreaFileParams,
    ILargeScreenGetAlarmListData,
    ILargeScreenGetAlarmListParams,
    ILargeScreenGetAlarmRankingData,
    ILargeScreenGetAlarmRankingParams,
    ILargeScreenGetCompanyCoordinatesData,
    ILargeScreenGetCompanyCoordinatesParams,
    ILargeScreenGetCompanyListByProvinceData,
    ILargeScreenGetCompanyListByProvinceParams,
    ILargeScreenGetCompanyScreenData,
    ILargeScreenGetCompanyScreenParams,
    ILargeScreenGetCompanyStatisticsData,
    ILargeScreenGetCompanyStatisticsParams,
    ILargeScreenGetEquipmentAlarmStatistics,
    ILargeScreenGetEquipmentAlarmStatisticsData,
    ILargeScreenGetLargeScreenStatistics,
    ILargeScreenGetLargeScreenStatisticsData,
    ILargeScreenGetMachineStatisticsData,
    ILargeScreenGetMachineStatisticsParams,
    ILargeScreenGetMappingAlarmStatisticsRecordData,
    ILargeScreenGetMappingAlarmStatisticsRecordParams,
    ILargeScreenGetSensorRankingData,
    ILargeScreenGetSensorRankingParams,
    ILargeScreenGetSensorStatisticsData,
    ILargeScreenGetSensorStatisticsParams,
    ILargeScreenSetCoordinatesParams,
    ILargeScreenGetOrdinaryCompanyStatisticsData,
    ILargeScreenGetOrdinaryCompanyStatisticsParams,
    ILargeScreenGetAlarmStatisticsParams,
    ILargeScreenGetAlarmStatisticsParamsData,
    ILargeScreenAddParentCompanyFileParams
} from "./largeScreen.types";

/**
 * 添加区域图片
 */
export function largeScreenAddAreaFile(params: ILargeScreenAddAreaFileParams) {
    return axios({
        method: 'post',
        url: '/large/screen/addAreaFile',
        data: params,
    })
}


/**
 * 添加公司图片
 */
export function largeScreenAddCompanyFile(params: ILargeScreenAddCompanyFileParams) {
    return axios({
        method: 'post',
        url: '/large/screen/addCompanyFile',
        data: params,
    })
}


/**
 * 获取最新报警
 */
export function largeScreenGetAlarmList(params: ILargeScreenGetAlarmListParams) {
    return axios<ILargeScreenGetAlarmListData>({
        method: 'get',
        url: '/large/screen/getAlarmList',
        params,
    })
}


/**
 * 获取区域图
 */
export function largeScreenGetAreaFile(params: ILargeScreenGetAreaFileParams) {
    return axios<ILargeScreenGetAreaFileData>({
        method: 'get',
        url: '/large/screen/getAreaFile',
        params,
    })
}


/**
 * 获取公司坐标详情
 */
export function largeScreenGetCompanyCoordinates(params: ILargeScreenGetCompanyCoordinatesParams) {
    return axios<ILargeScreenGetCompanyCoordinatesData>({
        method: 'get',
        url: '/large/screen/getCompanyCoordinates',
        params,
    })
}


/**
 * 公司大屏首页-每个区域设备传感器数量
 */
export function largeScreenGetCompanyScreen(params: ILargeScreenGetCompanyScreenParams) {
    return axios<ILargeScreenGetCompanyScreenData>({
        method: 'get',
        url: '/large/screen/getCompanyScreen',
        params,
    })
}


/**
 * 公司统计-设备/传感器/故障诊断数量
 */
export function largeScreenGetCompanyStatistics(params: ILargeScreenGetCompanyStatisticsParams) {
    return axios<ILargeScreenGetCompanyStatisticsData>({
        method: 'get',
        url: '/large/screen/getCompanyStatistics',
        params,
    })
}


/**
 * 传感器统计
 */
export function largeScreenGetSensorStatistics(params: ILargeScreenGetSensorStatisticsParams) {
    return axios<ILargeScreenGetSensorStatisticsData>({
        method: 'get',
        url: '/large/screen/getSensorStatistics',
        params,
    })
}

/**
 * 区域坐标设置
 */
export function largeScreenSetCoordinates(params: ILargeScreenSetCoordinatesParams) {
    return axios({
        method: 'post',
        url: '/large/screen/setCoordinates',
        data: params,
    })
}

/**
 * 普通公司数量统计 
 */
export function largeScreenGetOrdinaryCompanyStatistics(params: ILargeScreenGetOrdinaryCompanyStatisticsParams) {
    return axios<ILargeScreenGetOrdinaryCompanyStatisticsData>({
        method: 'get',
        url: '/large/screen/getOrdinaryCompanyStatistics',
        params,
    })
}


/**
 * 添加总公司图片
 */
export function largeScreenAddParentCompanyFile(params: ILargeScreenAddParentCompanyFileParams) {
    return axios({
        method: 'post',
        url: '/large/screen/addParentCompanyFile',
        data: params,
    })
}


/**
 * 报警趋势统计 
 */
export function largeScreenGetAlarmStatistics(params: ILargeScreenGetAlarmStatisticsParams) {
    return axios<ILargeScreenGetAlarmStatisticsParamsData>({
        method: 'get',
        url: '/large/screen/getAlarmStatistics',
        params,
    })
}


//#region 总公司大屏

/**
 * 总公司大屏 月报警排行
 */
export function largeScreenGetAlarmRanking(params?: ILargeScreenGetAlarmRankingParams) {
    return axios<ILargeScreenGetAlarmRankingData>({
        method: 'get',
        url: '/large/screen/getAlarmRanking',
        params,
    })
}


/**
 * 总公司大屏 月设备统计
 */
export function largeScreenGetEquipmentAlarmStatistics(params?: ILargeScreenGetEquipmentAlarmStatistics) {
    return axios<ILargeScreenGetEquipmentAlarmStatisticsData>({
        method: 'get',
        url: '/large/screen/getEquipmentAlarmStatistics',
        params,
    })
}


/**
 * 总公司大屏 地图统计和城市占比
 */
export function largeScreenGetLargeScreenStatistics(params?: ILargeScreenGetLargeScreenStatistics) {
    return axios<ILargeScreenGetLargeScreenStatisticsData>({
        method: 'get',
        url: '/large/screen/getLargeScreenStatistics',
        params,
    })
}


/**
 * 总公司大屏 左侧设备统计
 */
export function largeScreenGetMachineStatistics(params?: ILargeScreenGetMachineStatisticsParams) {
    return axios<ILargeScreenGetMachineStatisticsData>({
        method: 'get',
        url: '/large/screen/getMachineStatistics',
        params,
    })
}


/**
 *  总公司大屏 传感器排行
 */
export function largeScreenGetSensorRanking(params?: ILargeScreenGetSensorRankingParams) {
    return axios<ILargeScreenGetSensorRankingData>({
        method: 'get',
        url: '/large/screen/getSensorRanking',
        params,
    })
}

/**
 * 总公司大屏 根据省份获取公司
 */
export function largeScreenGetCompanyListByProvince(params: ILargeScreenGetCompanyListByProvinceParams) {
    return axios<ILargeScreenGetCompanyListByProvinceData>({
        method: 'get',
        url: '/large/screen/getCompanyListByProvince',
        params,
    })
}

/**
 * 总公司大屏 公司报警记录
 */
export function largeScreenAlaramHistory(params: ILargeScreenAlaramHistoryParams) {
    return axios({
        method: 'get',
        url: '/large/screen/alarm/history',
        params
    })
}

/**
 * 总公司大屏 月测点报警记录统计
 */
export function largeScreenGetMappingAlarmStatisticsRecord(params: ILargeScreenGetMappingAlarmStatisticsRecordParams) {
    return axios<ILargeScreenGetMappingAlarmStatisticsRecordData>({
        method: 'get',
        url: '/large/screen/getMappingAlarmStatisticsRecord',
        params,
    })
}

//#endregion