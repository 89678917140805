/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import Vue from "vue";
import { Store } from "vuex";
let installed = false
export function install(vue: typeof Vue, store: Store<any>) {
    if (installed) {
        return
    }
    function hasPerm(perm: string) {
        const hasPerm = store.getters.menuPermissionSet.has(perm)
        return hasPerm
    }

    vue.prototype.$hasPerm = hasPerm
    vue.directive('perm', {
        inserted: function (el, binding) {
            const toCheckPerm = binding.value
            if (!hasPerm(toCheckPerm)) {
                el.style.display = "none"
            }
        }
    })
}

declare module 'vue/types/vue' {
    interface Vue {
        $hasPerm(perm: string): boolean
    }
}
