<template>
    <div class="button" v-bind="$attrs" v-on="$listeners">
        <img src="@/assets/image/button/expand.svg" class="icon" />{{ $t('buttons.expand') }}
    </div>
</template>
<script>
export default {
    name: "expand-button",
}
</script>
<style lang="scss" scoped>
.button {
    display: inline-flex;
    background: #252944;
    color: rgba(239, 239, 239, 1);
    // width: 97px;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    border: 1px solid rgba(187, 187, 187, 1);
    align-items: center;

    &:active {
        background: rgba(64, 149, 229, 0.81);
    }

    .icon {
        margin-left: 8px;
        margin-right: 5px;
        width: 16px;
        height: 16px;                
    }
}
</style>