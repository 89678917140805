/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

@Component({
    name: 'DeleteWarning'
})
export class DeleteWarningImpl extends Vue {
    @Prop({
        type: Boolean
    }) visible: boolean

    @Prop({
        type: String
    }) title: string

    @Prop({
        type: String
    }) text: string

    @Prop({
        type: Boolean,
        default: false,
    }) useAnimationIcon: boolean

    onUpdateVisible() {
        this.$emit('update:visible')
    }

    onCancel() {
        this.$emit('cancel')
    }
    onConfirm() {
        this.$emit('confirm')
    }
}
