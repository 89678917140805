/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * 点检标准
 */
import axios from '../../axios'
import {
    IInspectionStandardAddParams,
    IInspectionStandardDetailData,
    IInspectionStandardEditParams,
    IInspectionStandardListData,
    IInspectionStandardListParams,
    IInspectionStandardThresholdData
} from './inspection_standard.types'

/**
 * 添加
 */
export function inspectionStandardAdd(params: IInspectionStandardAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/standard/add',
        data: params,
    })
}

/**
 * 删除
 */
export function inspectionStandardDel(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/standard/delete/${id}`,
    })
}

/**
 * 详情
 */
export function inspectionStandardDetail(params: {
    id: number
}) {
    return axios<IInspectionStandardDetailData>({
        method: 'get',
        url: '/inspection/standard/getDetail',
        params,
    })
}

/**
 * 自定义阈值
 */
export function inspectionStandardThreshold() {
    return axios<IInspectionStandardThresholdData>({
        method: 'get',
        url: '/inspection/standard/getThreshold',
    })
}

/**
 * 分页
 */
export function inspectionStandardList(params: IInspectionStandardListParams) {
    return axios<IInspectionStandardListData>({
        method: 'get',
        url: '/inspection/standard/pageList',
        params,
    })
}

/**
 * 修改
 */
export function inspectionStandardEdit(params: IInspectionStandardEditParams) {
    return axios({
        method: 'put',
        url: '/inspection/standard/update',
        data: params,
    })
}
