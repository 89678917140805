/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 点检路线

import axios from '../../axios'
import {
    IInspectionLineAddParams,
    IInspectionLineDetailData,
    IInspectionLinePageListData,
    IInspectionLinePageListParams,
    IInspectionLineAllPointData,
    IInspectionLineStandardDetailData,
    IInspectionLineUpdateParams
} from './inspection_line.types'

/**
 * 添加
 */
export function inspectionLineAdd(params: IInspectionLineAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/line/add',
        data: params,
    })
}

/**
 * 删除
 */
export function inspectionLineDelete(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/line/delete/${id}`,
    })
}

/**
 * 路线详情
 */
export function inspectionLineDetail(params: { id: number }) {
    return axios<IInspectionLineDetailData>({
        method: 'get',
        url: '/inspection/line/getDetail',
        params,
    })
}

/**
 * 所有巡检点
 */
export function inspectionLineAllPoint() {
    return axios<IInspectionLineAllPointData>({
        method: 'get',
        url: '/inspection/line/getAllPoint',
    })
}

/**
 * 巡检标准
 */
export function inspectionLineStandardDetail() {
    return axios<IInspectionLineStandardDetailData>({
        method: 'get',
        url: '/inspection/line/getStandardDetail',
    })
}

/**
 * 分页
 */
export function inspectionLinePageList(params: IInspectionLinePageListParams) {
    return axios<IInspectionLinePageListData>({
        method: 'get',
        url: '/inspection/line/pageList',
        params,
    })
}

/**
 * 修改
 */
export function inspectionLineUpdate(params: IInspectionLineUpdateParams) {
    return axios({
        method: 'put',
        url: '/inspection/line/update',
        data: params,
    })
}
