/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { isFailedAndReport, isFailedListAndReport } from '@/api/helper'
import { IClosePageItem } from '@/api/moudules/alarmLamp.types'
import { withDialogLoading } from '@/utils/task'
import { makeCommonQuery } from '@/view_helper/common'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

@Component({
    name: 'CloseAlarmLamp'
})
export class CloseAlarmLampImpl extends Vue {
    queryForm = makeCommonQuery()
    data: IClosePageItem[] = []
    pageTotal = 0
    onQuery() {
        const buildTask = async () => {
            const {
                pageNum,
                pageSize,
            } = this.queryForm

            const res = await this.$api.alarmLamp.closePage({
                pageNum,
                pageSize
            })
            if (isFailedListAndReport(this, res)) {
                this.data = []
                this.pageTotal = 0
                return
            }
            this.data = res.data.records
            this.pageTotal = res.data.total
        }
        withDialogLoading(this, buildTask())
    }


    onReset() {
        this.queryForm = makeCommonQuery()
        this.onQuery()
    }

    handleSizeChange(val: number) {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.onQuery()
    }

    handleCurrentChange(val: number) {
        this.queryForm.pageNum = val
        this.onQuery()
    }

    onCloseAlarmLamp(row: IClosePageItem) {
        const task = this.$api.alarmLamp.closeLamp(row.id).then(res => {
            if (isFailedAndReport(this, res)) {
                return
            }
            this.$message({
                type: 'success',
                message: this.$t('alarmLamp.closeSuccess') as string,
            })
            this.onReset()
        })
        withDialogLoading(this, task)
    }

    onClose() {
        this.$emit('close')
    }

    mounted() {
        this.onQuery()
    }
}
