/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 点检-保养

import axios from "../../axios";
import {
    IUpkeepAddContentParams,
    IUpkeepAddParams,
    IUpkeepDetailData,
    IUpkeepDetailParams,
    IUpkeepExamineParams,
    IUpkeepPageListData,
    IUpkeepPageListParams,
} from "./inspection_upkeep.types";

/**
 * 添加
 */
export function upkeepAdd(params: IUpkeepAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/upkeep/add',
        data: params,
    })
}

/**
 * 添加内容
 */
export function upkeepAddContent(params: IUpkeepAddContentParams) {
    return axios({
        method: 'post',
        url: '/inspection/upkeep/addContent',
        data: params,
    })
}

/**
 * 删除
 */
export function upkeepDelete(id: number) {
    return axios({
        method: 'delete',
        url: `/inspection/upkeep/delete/${id}`,
    })
}


/**
 * 详情
 */
export function upkeepDetail(params: IUpkeepDetailParams) {
    return axios<IUpkeepDetailData>({
        method: 'get',
        url: '/inspection/upkeep/getDetail',
        params,
    })
}

/**
 * 分页
 */
export function upkeepPageList(params: IUpkeepPageListParams) {
    return axios<IUpkeepPageListData>({
        method: 'get',
        url: '/inspection/upkeep/pageList',
        params,
    })
}

/**
 * 审核
 */
export function upkeepExamine(params: IUpkeepExamineParams) {
    return axios({
        method: 'put',
        url: '/inspection/upkeep/upkeepExamine',
        data: params,
    })
}