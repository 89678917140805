/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios";
import {
    E_Existed,
    ICreateSensorParams,
    IEditSensorParams,
    IGetSensorData,
    ISensorListPageData,
    ISensorListPageParams
} from "./sensor.types";

/**
 * 传感器列表
 */
export function sensorListPage(params: ISensorListPageParams) {
    return axios<ISensorListPageData>({
        url: '/sensor/findPage',
        method: 'post',
        data: params
    })
}

/**
 * 新增传感器
 */
export function createSensor(params: ICreateSensorParams) {
    return axios({
        url: '/sensor/save',
        method: 'post',
        data: params
    })
}

/**
 * 编辑传感器
 */
export function editSensor(params: IEditSensorParams) {
    return axios({
        url: '/sensor/edit',
        method: 'post',
        data: params
    })
}

/**
 * 获取传感器
 */
export function getSensor(params: { sensorId: string }) {
    return axios<IGetSensorData>({
        url: '/sensor/get',
        method: 'get',
        params,
    })
}

/**
 * 删除传感器
 */
export function delSensor(params: { sensorId: string }) {
    return axios({
        url: '/sensor/del',
        method: 'post',
        params
    })
}

/**
 * 传感器是否存在
 */
export function sensorExist(params: {
    sensorId: string
}) {
    return axios<E_Existed>({
        method: 'get',
        url: '/sensor/exist',
        params,
    })
}
