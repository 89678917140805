/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * 报警颜色
 * @param {*} item
 * @returns
 */
export function getAlarmColor(value) {
  if (value == 0) {
    return { color: "rgba(255, 255, 255, 1)" };
  } else if (value == 1) {
    return { color: "rgba(206, 206, 206, 1)" };
  } else if (value == 2) {
    return { color: "rgba(252, 202, 0, 1)" };
  } else if (value == 3) {
    return { color: "rgba(255, 82, 100, 1)" };
  } return {}
}
