/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import atlasAnalysis from '@/assets/image/menus/atlas-analysis.png'
import atlasAnalysis__active from '@/assets/image/menus/atlas-analysis__active.png'
import checkStatus from '@/assets/image/menus/check-status.png'
import checkStatus__active from '@/assets/image/menus/check-status__active.png'
import dataQueries from '@/assets/image/menus/data-queries.png'
import dataQueries__active from '@/assets/image/menus/data-queries__active.png'
import deviceManagement from '@/assets/image/menus/device-management.png'
import deviceManagement__active from '@/assets/image/menus/device-management__active.png'
import diagnosticAnalysis from '@/assets/image/menus/diagnostic-analysis.png'
import diagnosticAnalysis__active from '@/assets/image/menus/diagnostic-analysis__active.png'
import largeScreen from '@/assets/image/menus/large-screen.png'
import largeScreen__active from '@/assets/image/menus/large-screen__active.png'
import onlineMonitor from '@/assets/image/menus/online-monitor.png'
import onlineMonitor__active from '@/assets/image/menus/online-monitor__active.png'
import systemAdministration from '@/assets/image/menus/system-administration.png'
import systemAdministration__active from '@/assets/image/menus/system-administration__active.png'
import pointMeasure from '@/assets/image/menus/point-measure.png'
import pointMeasure__active from '@/assets/image/menus/point-measure__active.png'
import customerManagement from '@/assets/image/menus/customer-management.png'
import customerManagement__active from '@/assets/image/menus/customer-management__active.png'
import inspection from '@/assets/image/menus/inspection.png'
import inspection__active from '@/assets/image/menus/inspection__active.png'
import firmwareUpgrad from '@/assets/image/menus/firmware-upgrad.png'
import firmwareUpgrad__active from '@/assets/image/menus/firmware-upgrad.png__active.png'

import { IMenuItem } from '@/components/MainMenu/MainMenuImpl'
import Vue from 'vue'
import { IUserMenu } from '@/api/moudules/system.types'


export {
    /**
     * 图谱分析
     */
    atlasAnalysis,
    atlasAnalysis__active,
    /**
     * 健康检测
     */
    checkStatus,
    checkStatus__active,
    /**
     * 数据查询
     */
    dataQueries,
    dataQueries__active,
    /**
     * 设备管理
     */
    deviceManagement,
    deviceManagement__active,
    /**
     * 诊断分析
     */
    diagnosticAnalysis,
    diagnosticAnalysis__active,
    /**
     * 大屏展示
     */
    largeScreen,
    largeScreen__active,
    /**
     * 在线监测
     */
    onlineMonitor,
    onlineMonitor__active,
    /**
     * 系统管理
     */
    systemAdministration,
    systemAdministration__active,
    /**
     * 点测
     */
    pointMeasure,
    pointMeasure__active,
    /**
     * 客户管理
     */
    customerManagement,
    customerManagement__active,
    /**
     * 固件升级
     */
    firmwareUpgrad,
    firmwareUpgrad__active,
}

export function makeMainMenuItems(vm: Vue) {
    const mainMenuItems: IMenuItem[] = [
        {
            icon: largeScreen,
            icon_active: largeScreen__active,
            title: vm.$t('menu.largeScreen') as string,
            router: 'largeScreenNormal',
            permission: 'business:large:screen',
        },
        {
            icon: onlineMonitor,
            icon_active: onlineMonitor__active,
            title: vm.$t('menu.onlineMonitor') as string,
            router: 'onlineMonitor',
            permission: 'business:online:monitor',
        },
        {
            icon: diagnosticAnalysis,
            icon_active: diagnosticAnalysis__active,
            title: vm.$t('common.diagnosticAnalysis') as string,
            permission: 'business:alarm:home',
            children: [{
                title: vm.$t('alarm.alarmReport') as string,
                router: 'alarmReport',
                permission: 'business:alarm:list',
            }, {
                title: vm.$t('fault.faultAnalysis') as string,
                router: 'faultAnalysisDefault',
                permission: 'business:diagnosis:list',
            },]
        },
        {
            icon: checkStatus,
            icon_active: checkStatus__active,
            title: vm.$t('menu.healthTesting') as string,
            router: 'healthTesting',
            permission: 'business:health:testing',
        },
        {
            icon: dataQueries,
            icon_active: dataQueries__active,
            title: vm.$t('menu.dataQuery') as string,
            permission: 'business:data:home',
            children: [{
                title: vm.$t('dataSearch.realtimeData') as string,
                router: 'realtimeData',
                permission: 'business:data:current:list',
            }, {
                title: vm.$t('dataSearch.historyData') as string,
                router: 'historyData',
                permission: 'business:data:history:list',
            },
            {
                title: vm.$t('dataSearch.weeklyReport') as string,
                router: 'weeklyReport',
                permission: 'business:week',
            },
            {
                title: vm.$t('yangshan.rvt50WeeklyReport') as string,
                router: 'RVT50weeklyReport',
                permission: 'business:ysweekly:list',
            }]
        },
        {
            icon: atlasAnalysis,
            icon_active: atlasAnalysis__active,
            title: vm.$t('menu.waveSpectrum') as string,
            router: 'waveSpectrum',
            permission: 'business:wave:spectrum',
        },
        {
            icon: pointMeasure,
            icon_active: pointMeasure__active,
            title: vm.$t('menu.inspectionTask') as string,
            permission: 'business:inspection',
            children: [{
                title: vm.$t('menu.inspectionTask') as string,
                router: 'inspectionTask',
                permission: 'business:task:list',
            }, {
                title: vm.$t('menu.inspectGroup') as string,
                router: 'inspectionGroup',
                permission: 'business:group:list',
            },
            {
                title: vm.$t('menu.inspectPointManager') as string,
                router: 'inspectionPoint',
                permission: 'business:point:list',
            },
            {
                title: vm.$t('inspection.inspectLine') as string,
                router: 'inspectionLine',
                permission: 'business:line:list',
            },
            {
                title: vm.$t('inspection.inpectionStandard') as string,
                router: 'inspectionStandard',
                permission: 'business:standard:list',
            },
            {
                title: vm.$t('menu.inspectAreaManagement') as string,
                router: 'inspectionArea',
                permission: 'business:company:list',
            },
            {
                title: vm.$t('inspection.dangerLibrary') as string,
                router: 'inspectionDangerLib',
                permission: 'business:danger:lib:list',
            },
            {
                title: vm.$t('menu.inspectDanger') as string,
                router: 'inspectionDanger',
                permission: 'business:danger:list',
            },
            {
                title: vm.$t('menu.inspectionUpkeepLib') as string,
                router: 'inspectionUpkeepLib',
                permission: 'business:upkeep:lib:list',
            },
            {
                title: vm.$t('menu.inspectionUpkeep') as string,
                router: 'inspectionUpkeep',
                permission: 'business:upkeep:list',
            },
            {
                title: vm.$t('inspection.repairExperience') as string,
                router: 'inspectionRepairLib',
                permission: 'business:repair:lib:list',
            },
            {
                title: vm.$t('menu.inspectRepair') as string,
                router: 'inspectionRepair',
                permission: 'business:repair:list',
            },
            {
                title: vm.$t('inspection.weekReport') as string,
                router: 'inspectWeekReport',
                permission: 'business:inspection:week:list',
            },
            ]
        },
        {
            icon: deviceManagement,
            icon_active: deviceManagement__active,
            title: vm.$t('menu.configManagement') as string,
            permission: 'business:setting',
            children: [
                {
                    title: vm.$t('menu.deviceScreem') as string,
                    router: 'deviceScreen',
                    permission: 'business:equipment:screen:list',
                },
                {
                    title: vm.$t('menu.subCompanyManagement') as string,
                    router: 'subCompanyManagement',
                    permission: 'business:company:list',
                },
                {
                    title: vm.$t('menu.gatewayManagement') as string,
                    router: 'gatewayManagement',
                    permission: 'business:gateway:list',
                },
                {
                    title: vm.$t('menu.modelManagement') as string,
                    router: 'modelManagement',
                    permission: 'business:model:list',
                },
                {
                    title: vm.$t('menu.equipmentManagement') as string,
                    router: 'equipmentManagement',
                    permission: 'business:equipment:list',
                },
                // {
                //     title: vm.$t('menu.mappingManagement') as string,
                //     router: 'mappingManagement',
                //     permission: 'business:mapping:list',
                // },
                // {
                //     title: vm.$t('menu.sensorManagement') as string,
                //     router: 'sensorManagement',
                //     permission: 'business:sensor:list',
                // },
                {
                    title: vm.$t('menu.sensorMapping') as string,
                    router: 'sensorManagementNew',
                    permission: 'business:new:sensor:list',
                },
                // {
                //     title: vm.$t('menu.equipmentComponentManagement') as string,
                //     router: 'equipmentComponentManagement',
                //     permission: 'business:component:list',
                // },
                {
                    title: vm.$t('menu.bearingManagement') as string,
                    router: 'bearingManagement',
                    permission: 'business:bear:list',
                }, {
                    title: vm.$t('menu.thresholdManagement') as string,
                    router: 'thresholdManagement',
                    permission: 'business:threshold:list',
                },
                {
                    title: vm.$t('menu.alarmLamp') as string,
                    router: 'alarmLampManagement',
                    permission: 'business:lamp:list',
                },
                {
                    title: vm.$t('menu.collectCard') as string,
                    router: 'collectCardManagement',
                    permission: null,
                },
            ]
        },
        {
            icon: systemAdministration,
            icon_active: systemAdministration__active,
            title: vm.$t('menu.systemManagement') as string,
            permission: 'system:home',
            children: [
                {
                    title: vm.$t('menu.accountManagement') as string,
                    router: 'userList',
                    permission: 'system:user:list',
                },
                {
                    title: vm.$t('menu.authorityManagement') as string,
                    router: 'authorityManagement',
                    permission: 'business:authority:list',
                },
                {
                    title: vm.$t('menu.roleManagement') as string,
                    router: 'roleList',
                    permission: 'system:role:list',
                },
                {
                    title: vm.$t('menu.emailManagement') as string,
                    router: 'emailManagement',
                    permission: 'business:email:list',
                },
                // {
                //     title: '菜单管理',
                //     router: 'menuList',
                // },
                // {
                //     title: '字典管理',
                //     router: 'dicList',
                // },
                // {
                //     title: '操作日志',
                //     router: 'dicListData',
                // },
            ]
        },
        {
            isSuperAdminMenu: true,
            icon: inspection,
            icon_active: inspection__active,
            id: 'inspection',
            title: vm.$t('menu.inspect') as string,
            permission: '',
            children: [
                {
                    title: vm.$t('menu.appUpdate') as string,
                    router: 'appver'
                },
                {
                    title: vm.$t('menu.appArticles') as string,
                    router: 'appArticles'
                }, {
                    title: vm.$t('menu.appBanner') as string,
                    router: 'appBanner',
                }, {
                    title: vm.$t('inspection.companyIntro') as string,
                    router: 'appBrief',
                }, {
                    title: vm.$t('menu.appAsk') as string,
                    router: 'ask',
                }
            ]
        },
        {
            isSuperAdminMenu: true,
            icon: customerManagement,
            icon_active: customerManagement__active,
            id: 'customerManagement',
            title: vm.$t('menu.customerManagement') as string,
            permission: '',
            children: [
                {
                    title: vm.$t('menu.customerManagement') as string,
                    router: 'customerManagement_tenant',
                    permission: '',
                },
                {
                    title: vm.$t('menu.menuManagement') as string,
                    router: 'customerManagement_menu',
                    permission: '',
                },
                // {
                //     title: vm.$t('menu.dictManagement') as string,
                //     router: 'customerManagement_dictList',
                // },
                {
                    title: vm.$t('menu.operatorLog') as string,
                    router: 'customerManagement_operateLog',
                    permission: '',
                },
                {
                    title: vm.$t('menu.loginLog') as string,
                    router: 'customerManagement_loginLog',
                    permission: '',
                },
            ]
        },
        {
            isSuperAdminMenu: true,
            icon: largeScreen,
            icon_active: largeScreen__active,
            router: 'largeScreenAdmin',
            id: 'largeScreenAdmin',
            permission: '',
            title: '大屏',
        },
        // {
        //     isSuperAdminMenu: true,
        //     icon: firmwareUpgrad,
        //     icon_active: firmwareUpgrad__active,
        //     id: 'firmwareManagement',
        //     permission: '',
        //     title: '固件升级',
        //     children: [
        //         {
        //             title: '版本升级',
        //             router: 'firmwareUpgrad',
        //             permission: '',
        //         },
        //         {
        //             title: '升级列表',
        //             router: 'firmwareUpgradList',
        //             permission: '',
        //         },
        //     ]
        // },
    ]
    return mainMenuItems
}
