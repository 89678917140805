/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from '../../axios'
import { IBannerAddParams, IBannerPageListData } from './inspection_banner.types'
/// 轮播文章

export function bannerAdd(params: IBannerAddParams) {
    return axios({
        method: 'post',
        url: '/inspection/banner/add',
        data: params,
    })
}

export function bannerPageList() {
    return axios<IBannerPageListData>({
        method: 'get',
        url: '/inspection/banner/pageList',
    })
}
